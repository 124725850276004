import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import router from '@/router'

import authorization from './plugins/auth'
import api from './plugins/api'

import notify from '@ananasbear/notify'
import validate from '@ananasbear/validation'
import i18n from '@/plugins/vue-i18n'
import Table from '@/components/layauts/Table'
import Loader from '@/components/Loader'
import Vuelidate from 'vuelidate'
import moment from 'moment'
import VueSwal from 'vue-swal'
import Vuesax from 'vuesax'
import device from 'vue-device-detector'
import VueQRCodeComponent from 'vue-qrcode-component'
import VueMask from 'v-mask'
import PageTemplate from '@/components/PageTemplate'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const lang = window.localStorage.getItem('lang') === 'ua' ? 'uk' : 'ru'
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPTCHA,
  lang
})
 
import 'vuesax/dist/vuesax.css'
Vue.use(Vuesax)
Vue.use(VueMask);

Vue.component('Table', Table)
Vue.component('Loader', Loader)
Vue.component('QrCode', VueQRCodeComponent)
Vue.component('PageTemplate', PageTemplate)
Vue.use(Vuelidate)
Vue.use(VueSwal)
Vue.use(device)

// Styles
import '@/assets/sass/fonts.sass'
import '@/assets/sass/components/buttons.sass'
import '@/assets/sass/colors.sass'
import '@/assets/sass/constants.sass'
import '@/assets/sass/language.sass'
import '@/assets/sass/modal.sass'
import '@/assets/sass/components/modal.sass'
import '@/assets/sass/components/slider.sass'



Vue.config.productionTip = false

Vue.prototype.$notification = notify
Vue.prototype.$api = api
Vue.prototype.$auth = authorization
Vue.prototype.$valid = validate
Vue.prototype.$moment = moment

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
