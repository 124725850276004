const apiVersion = 'api/v1'
const apiVersion2 = 'api/v2'

export const authUrls = {
	general: {
    admin: `${apiVersion2}/login_admin/?`,
		login: `${apiVersion}/get_sms/`
	},
	send_message: `${apiVersion}/send_message/`,
	get_sms: `${apiVersion}/get_sms/`,
	lp_login: `${apiVersion}/lp_login/`,
	resend_message: `${apiVersion}/resend_message/`,
	set_account: `${apiVersion}/set_account/`,
  qr_code: `${apiVersion}/authorization/qr_code/`,
  qr_code_for_mobile: `${apiVersion}/authorization/qr_code_for_mobile/`
}

export const userInfoUrls = {
  edit_data: `${apiVersion}/edit_data/`,
  get_data: `${apiVersion}/get_data/`
}

export const clientProfile = {
	avatar: `${apiVersion}/client/avatar/`,
	clientAvatar: `${apiVersion}/client/client_avatar/`,
	profile: `${apiVersion}/client/profile/`,
  userContact: `${apiVersion}/client/user_contact/`,
  social: `${apiVersion}/client/social/`,
  clientSocial: `${apiVersion}/client/client_social/`
}

export const accountUrls = {
  get_list: `${apiVersion}/list_account/`,
  change_account: `${apiVersion}/change_account/`,
  remove_account: `${apiVersion}/remove_account/`,
  post_account: `${apiVersion}/add_account/`
}

export const paymentUrls = {
  get_payments: `${apiVersion}/get_payments/`,
  get_fees: `${apiVersion}/get_fees/`,
  payment_urls: `${apiVersion}/payment_urls/`,
  liqpay_url: `${apiVersion}/set_liqpay/`,
  monopay_url: `${apiVersion}/payments/mono/invoice/`,
  get_payment_history: `${apiVersion}/get_payment_history/`,
  payment_methods: `${apiVersion2}/payment_types/`,
  privat: `${apiVersion}/payments/privatbank/invoice/`
}

export const tariffUrls = {
  get_tarif: `${apiVersion}/get_tarif/`,
  change_tarif: `${apiVersion}/change_tarif/`,
  get_ip_info: `${apiVersion}/get_service/`,
  set_ip: `${apiVersion}/set_real_ip/`,
  delete_ip: `${apiVersion}/remove_real_ip/`,
	freeze_unfreeze: `${apiVersion2}/client_services/freeze/`,
  set_credit: `${apiVersion}/set_credit/`,
  tariff_cost: `${apiVersion}/tariff_cost/`,
  check_ability_change_megogo_tariff: `${apiVersion}/check_ability_change_megogo_tariff/`
}

export const tvUrls = {
  get_site_access_link: `${apiVersion}/get_site_access_link/`,
  get_tv_channels: `${apiVersion}/tv/get_list_channels/`,
  change_status_tv: `${apiVersion}/tv_integration/activate_tv/`,
  get_tv_info: `${apiVersion}/tv_integration/status_tv/`,
  get_tv_trinity_info: `${apiVersion}/tv_integration/trinity_info/`,
  get_screens: `${apiVersion}/tv_integration/list_screens/`,
  add_screen: `${apiVersion}/tv_integration/add_screen/`,
  remove_screen: `${apiVersion}/tv_integration/remove_screen/`,
  add_playlist: `${apiVersion}/tv_integration/create_iptv_playlist/`,
  get_tv_tariff: `${apiVersion}/tv_integration/advanced_tariff_plan/`,
  buy_tv_tariff: `${apiVersion}/tv_integration/buy_advanced_tariff/`,
  remove_tv_tariff: `${apiVersion}/tv_integration/disable_advanced_tariff/`,
  change_megogo_credentials: `${apiVersion}/change_megogo_credentials/`
}

export const additionalUrls = {
	get_data: 'https://dianet.online/api/v1/market/services/'
}

export const feedbackUrl = `${apiVersion}/poll/quality_poll/`
export const messagesUrls = {
	get_data: `${apiVersion}/notification/abon_notification/`
}

export const bonusesUrls = {
  achievement: `${apiVersion}/bonus/achievement/`,
  bonus_balance: `${apiVersion}/bonus/bonus_balance/`,
  bonus_transactions: `${apiVersion}/bonus/bonus_transaction/`,
  transfer_bonuses_to_bill: `${apiVersion}/bonus/bonus_balance/transfer_bonuses_to_bill/`,
  bonus_category: `${apiVersion}/bonus/category/`,
  client_achievement: `${apiVersion}/bonus/client_achievement/`,
  loyalty_program: `${apiVersion}/bonus/loyalty_program/`,
  referral_url: `${apiVersion}/bonus/referral/url/`,
  referral: `${apiVersion}/bonus/referral/approved/`
}
