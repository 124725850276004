<template lang="pug">
v-dialog.modal(v-model="isOpen" @click:outside="postponeFeedBack" content-class="elevation-0")
  .modal-dialog.modal-feedback
    .modal-content.modal-feedback-content
      .modal-header
        h2.modal-header__title {{ $t('feedback.rateQuality') }}
        button.close(@click="postponeFeedBack")
      .modal-body
        p.modal-body__description {{ feedSend ? $t('feedback.tellFriends') : $t('feedback.tellUs') }}
        .stars
          template(v-if="feedSend")
            .star-container(v-for="index in 5" :key="index")
              .star.star-fill
          template(v-else)
            .star-container(v-for="index in 5" :key="index"  @mouseover="changeHover(index + 1)" @mouseout="emptyHover()")
              .star(:class="hoverStar > index || selectedStar >= index ? 'star-fill' : ''" @click="selectStar(index)")
        v-textarea.modal-body__textarea(v-if="!feedSend" v-model="text" :placeholder="$t('feedback.writeMessage')" hide-details dense background-color="#F8F8F8" no-resize)
      .modal-footer
        button.mainButton.secondaryButton(@click="postponeFeedBack") {{ $t('reject') }}
        button.mainButton(v-if="feedSend")
          a(@click="hidePopUp({is_ready_to_poll: false})" href="https://g.page/r/CWcnddPdLgjTEA0/review" target="blank") {{ $t('feedback.google') }}
        button.mainButton(v-else @click="sendFeedBack" :disabled="!selectedStar") {{ $t('confirm') }}
</template>
<script>
import { mapActions, mapMutations } from 'vuex'

export default {
	name: 'ModalFeedback',
	props: {
		isOpen: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			hoverStar: 0,
			selectedStar: null,
			text: null,
			feedSend: false
		}
	},
	methods: {
		...mapActions(['sendFeed']),
		...mapMutations({
			hidePopUp: 'SET_DATA_USER'
		}),
		changeHover (star) {
			this.hoverStar = star
		},
		emptyHover () {
			this.hoverStar = 0
		},
		selectStar (star) {
			this.selectedStar = star
		},
		postponeFeedBack () {
			if (!this.feedSend) this.sendFeed({ is_poll_rejected: true })
		},
		sendFeedBack () {
			if (this.selectedStar === 5) this.feedSend = true
			this.sendFeed({
				rating: this.selectedStar,
				comment: this.text || '',
				poll_type: 'internet_quality',
				is_from_web_lk: true
			})
		}
	}
}
</script>
