import { authUrls, userInfoUrls, accountUrls, clientProfile, feedbackUrl } from '@/configs/endpoints'
import api from '@/plugins/api'
import auth from '@/plugins/auth'

export default {
    state: () => ({
        userInfo: {},
        state: 0,
        userListAccount: [],
        currentPhone: sessionStorage.getItem('phone'),
        accounts: [],
        currentAccount: '',
        clientProfile: {
          language: localStorage.getItem('lang') || 'ua'
        },
        ip: undefined,
        is_ready_to_poll: false,
        qrCodeKey: '',
        isLoading: false,
        qrCodeForMobile: '',
        isError: false
    }),
    mutations: {
        SET_DATA_USER (state, data) {
            Object.entries(data).forEach(entry =>{
                state[entry[0]] = entry[1]
            })
        }
    },
    actions: {
      async getUserInfo ({ commit, getters }) {
        const response = await api.get(userInfoUrls.get_data)
        response.data.phone = Array.from(new Set(response.data.phone))
        if (!getters.isAdmin) window.dashly.identify({
          Name: response.data.fio,
          phone: response.data.phone,
          Email: response.data.email,
          login: response.data.login,
          'User ID': response.data.login,
          address: response.data.adress
        })
        commit('SET_DATA_USER', {
          userInfo: response.data,
          is_ready_to_poll: response.data.is_ready_to_poll, state: response.data.state
        })
        return true
      },
      async getUserListAccount ({ commit }) {
        try {
          //commit('SET_LOAD', true)
          const response = await api.get(accountUrls.get_list)
          commit('SET_DATA_USER', { accounts: response.data.accounts })
        } catch (error) {
          console.log(error)
        } finally {
          //commit('SET_LOAD', false)
        }
      },
      async postChangeAccount ({ commit }, payload) {
        try {
          commit('SET_LOAD', true)
          await api.post(accountUrls.change_account, payload)
        } catch (error) {
          console.log(error)
        } finally {
          commit('SET_LOAD', false)
        }
      },
      async postLogoutAnotherAcc ({ commit }, payload) {
        try {
          commit('SET_LOAD', true)
          await api.post(accountUrls.remove_account, payload)
        } catch (error) {
          console.log(error)
        } finally {
          commit('SET_LOAD', false)
        }
      },
      async postAddAccount (context, payload) {
        try {
          const response = await api.post(accountUrls.post_account, payload)
          return response.data
        } catch (error) {
          console.log(error)
        }
      },
      async getClientProfile ({ commit, state }) {
        try {
          commit('SET_LOAD', true)
          const response = await api.get(clientProfile.profile)
          if (response.code === 200) commit('SET_DATA_USER', { clientProfile: response.data })
          if (response.data.avatars && response.data.avatars.length > 0) {
            const responsePhoto = await api.get(response.data.avatars[0].photo, { responseType: 'blob' })
            if ( [200, 201].includes(responsePhoto.code) )
            {
              commit('SET_DATA_USER', {
                clientProfile: {
                  ... state.clientProfile,
                  file:  window.URL.createObjectURL(new File(
                    [responsePhoto.data],
                    'file_name',
                    { lastModified: new Date(), type: responsePhoto.data.type }))
                  }
                }
              )
            }
          }
        } catch (error) {
          console.log(error)
        } finally {
          commit('SET_LOAD', false)
        }
      },

      async patchClientProfile ({ commit, state }, payload) {
        try {
          commit('SET_LOAD', true)
          const response = await api.patch(clientProfile.profile, payload)
          commit('SET_DATA_USER', { clientProfile: {...state.clientProfile, language: response.data.language} })
        } catch (error) {
          console.log(error)
        } finally {
          commit('SET_LOAD', false)
        }
      },
      async getSMSCode (context, { phone, recaptcha }) {
          context.commit('SET_DATA_USER', { currentPhone: phone })
          sessionStorage.setItem('phone', phone)
          const response = await api.post(authUrls.send_message, { body: { phone, recaptcha } })
          return response.data
      },
      async setQRCode ({commit}) {
        try {
          commit('SET_DATA_USER', { isLoading: true })
          const response = await api.post(authUrls.qr_code)
          commit('SET_DATA_USER', { qrCodeKey: response.data.key })
          commit('SET_DATA_USER', { isLoading: false })
        } catch (error) {
          commit('SET_DATA_USER', { isError: true })
        } finally {
          commit('SET_DATA_USER', { isLoading: false })
        }
      },
      async setQRCodeForMobile ({commit}) {
        try {
          commit('SET_DATA_USER', { isLoading: true })
          const response = await api.post(authUrls.qr_code_for_mobile)
          commit('SET_DATA_USER', { qrCodeForMobile: response.data.qr_code })
          commit('SET_DATA_USER', { isLoading: false })
        } catch (error) {
          console.log(error)
        } finally {
          commit('SET_DATA_USER', { isLoading: false })
        }
      },
      async checkQR (context, payload) {
        try {
          const response = await api.get(`${authUrls.qr_code}${payload}/`)
          return response.data
        } catch (error) {
          console.log(error)
        }
      },
      async auth (context, data) {
          const response = await auth.byCredits(data)
          if (response.data.status === 1) context.commit('SET_DATA_USER', { accounts: response.data.accounts })
          return response.data
      },
      async authAdmin (context, data) {
        await auth.byAdmin(data)
      },
      async authLogPassw (context, data) {
          const response = await api.post(authUrls.lp_login, { body: { ...data, phone: context.state.currentPhone } })
          return response.data
      },
      async resendMessage (context, phone) {
          const response = await api.post(authUrls.resend_message, { body: { phone } })
          return response.data
      },
      async setUserAccount (context, uid) {
          const response = await api.post(authUrls.set_account, { body: { uid } })
          return response.data
      },
      async editUser ({commit, state}, data) {
        try {
          commit('SET_DATA_USER', { isLoading: true })
          const response = await api.post(userInfoUrls.edit_data, { body: { ...data } })
          if (Array.isArray(response.data)){
            const userInfo = state.userInfo
            userInfo.email = data.data_arr[0].value
            userInfo.phone = data.data_arr.slice(1, data.data_arr.length).map(item => item.value)
            commit('SET_DATA_USER', { userInfo })
            commit('SET_DATA_USER', { isLoading: false })
            return true
          }
          return false
        } catch (error) {
          console.log(error)
        } finally {
          commit('SET_DATA_USER', { isLoading: false })
        }
      },
      async getIp ({ commit, getters }) {
        try {
          const response = await fetch('https://jsonip.com')
          if (response.ok){
            const data = await response.json()
            commit('SET_DATA_USER', { ip: data.ip })
            if (!getters.isAdmin) window.dashly.identify({
              ip: data.ip
            })
          }
        } catch (error) {
          console.error('Failed to fetch user IP address: ', error)
        }

      },
      sendFeed ({ commit }, data) {
        api.post(feedbackUrl, { body: { ...data } })
        commit('SET_DATA_USER', { is_ready_to_poll: false })
      }
    },
    getters: {
      isAdmin: () => () => JSON.parse(localStorage.getItem('isAdmin'))
    }
  }
