<template lang="pug">
  .loader(:class="{'loader-content': isContent, 'bg-transparent': isBgTransparent}")
    img.loader__img(:src="require('../assets/images/loader-img.png')" width="115" height="115")
</template>
<script>
export default {
  name: 'Loader',
  props: {
    isContent: {
      type: Boolean,
      default: false
    },
    isBgTransparent: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="sass">
  .loader
    padding: 24px
    height: 100%
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    &__img
      -webkit-animation: rotating 2s linear infinite
      -moz-animation: rotating 2s linear infinite
      -ms-animation: rotating 2s linear infinite
      -o-animation: rotating 2s linear infinite
      animation: rotating 2s linear infinite
    &-content 
      position: absolute
      width: 100%
      height: 100%
      background-color: rgba(0,0,0,.2)
      display: -webkit-flex
      display: -ms-flex
      display: flex
      -ms-align-items: center
      align-items: center
      justify-content: center
      z-index: 1
      border-radius: inherit 
      top: 0
      left: 0
      // .loader__img
      //   width: 32px
      //   height: 32px
    &.bg-transparent   
      background-color: transparent  
  @-webkit-keyframes rotating
    from 
      -webkit-transform: rotate(0deg)
      -o-transform: rotate(0deg)
      transform: rotate(0deg)
    to 
      -webkit-transform: rotate(360deg)
      -o-transform: rotate(360deg)
      transform: rotate(360deg)
  @keyframes rotating
    from
      -ms-transform: rotate(0deg)
      -moz-transform: rotate(0deg)
      -webkit-transform: rotate(0deg)
      -o-transform: rotate(0deg)
      transform: rotate(0deg)
    to
      -ms-transform: rotate(360deg)
      -moz-transform: rotate(360deg)
      -webkit-transform: rotate(360deg)
      -o-transform: rotate(360deg)
      transform: rotate(360deg)
</style>