import { i18n } from '@/plugins/vue-i18n.js'

export const setCookie = function (cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const parseSpeed = function (speedTarif) {
    const speed = parseSpeedNumber(speedTarif)
    const value = parseSpeedValue(speedTarif)
	if (speedTarif === 'Unlimited') return `до ${speed} ${value}`
	return `${speed}<span> ${value}</span>`
}

export const parseSpeedNumber = function (speedTarif) {
	if (speedTarif === 'Unlimited') return 1
	let speed = speedTarif / 1049
	let speedParse =
		speed % 5 >= 2.5 ? (parseInt(speed / 5) * 5) + 5 : parseInt(speed / 5) * 5
	if (speedParse === 490) speedParse = 500
	if (speedParse === 195) speedParse = 200
	return speedParse
}

export const parseSpeedValue = function (speedTarif) {
	return speedTarif === 'Unlimited' ? i18n.t('gBit') : i18n.t('mBit')
}

export const checkPaySystem = system_id => {
    switch (parseInt(system_id)) {
        case 0:
            return 'Наличные'
        case 1:
            return 'Банк'
        case 2:
            return 'Внешние платежи'
        case 3:
            return 'Credit Card'
        case 4:
            return 'Бонус'
        case 5:
            return 'Корректировка'
        case 6:
            return 'Компенсация'
        case 7:
            return 'Перевод личных средств'
        case 8:
            return 'Пересчитать'
        case 41:
            return 'Webmoney'
        case 42:
            return 'Rbkmoney'
        case 43:
            return 'SMSProxy'
        case 44:
            return 'OSMP'
        case 45:
            return 'Portmone'
        case 46:
            return 'Ukrpays'
        case 47:
            return 'USMP'
        case 48:
            return 'Privat Bank (Visa/Master Cards)'
        case 49:
            return 'Pegas'
        case 50:
            return 'Comepay'
        case 51:
            return '24_non_STOP'
        case 52:
            return '24_non_STOP_SELF'
        case 53:
            return 'Express_Oplata'
        case 54:
            return 'Privat Bank - Privat 24'
        case 55:
            return 'Pay4'
        case 56:
            return 'АИС ЕРИП'
        case 57:
            return 'EasySoft'
        case 58:
            return 'Liberty Reserve'
        case 59:
            return 'QIWI'
        case 60:
            return 'Ibox'
        case 61:
            return 'OSMPv4'
        case 62:
            return 'LiqPAY'
        case 63:
            return 'UkrNET'
        case 64:
            return 'Regulpay'
        case 65:
            return 'Privat - Terminal'
        case 66:
            return 'Paypal'
        case 67:
            return 'Sberbank'
        case 68:
            return 'Gigs'
        case 69:
            return 'Autopay'
        case 70:
            return 'CyberPlat'
        case 71:
            return 'Telcell'
        case 72:
            return 'Ipay'
        case 73:
            return 'Yandex'
        case 74:
            return 'Alpha-pay'
        case 75:
            return 'Zaplati'
        case 76:
            return 'Paynet'
        case 77:
            return 'CyberPlat Visa/Master cards'
        case 78:
            return 'Epay'
        case 79:
            return 'Private bank terminal (json)'
        case 80:
            return 'Pegas Self Terminals'
        case 81:
            return 'Payonline - QIWI'
        case 82:
            return 'Payonline - WebMoney'
        case 83:
            return 'Payonline - Yandex money'
        case 84:
            return 'Payonline - Bank card'
        case 85:
            return 'Термінали України'
        case 86:
            return 'Perfectmoney'
        case 87:
            return 'OKPay'
        case 88:
            return 'Bitcoin'
        case 89:
            return 'Smsonline'
        case 90:
            return 'Cashcom'
        case 91:
            return 'PayU'
        case 92:
            return 'CoPayCo'
        case 93:
            return 'Minbank'
        case 94:
            return 'Redsys'
        case 95:
            return 'Gateway Technologies'
        case 96:
            return 'Webmoney UA'
        case 97:
            return 'PayMaster'
        case 98:
            return 'Ecommerce Connect (UPC)'
        case 99:
            return 'TYME'
        case 100:
            return 'E-manat'
        case 101:
            return 'Evostok'
        case 102:
            return 'Stripe'
        case 103:
            return 'Oschadbank'
        case 104:
            return 'Kaznachey'
        case 106:
            return 'Paykeeper'
        case 105:
            return 'Robokassa'
        case 107:
            return 'Chelyabinvestbank'
        case 108:
            return 'Platon'
        case 109:
            return 'Fondy'
        case 110:
            return 'Walletone'
        case 111:
            return 'Mobilnik'
        case 112:
            return 'Idram'
        case 114:
            return 'Oschadbank'
        case 115:
            return 'Goldenpay'
        case 116:
            return 'Mixplat'
        case 117:
            return 'Яндекс Касса'
        case 118:
            return 'Deltapay'
        case 119:
            return 'Unipay'
        case 120:
            return 'Tinkoff'
        case 121:
            return 'Cloudpayments'
        case 122:
            return 'Paymaster Ru'
        case 123:
            return 'РНКБ'
        case 124:
            return 'Выписки от ПриватБанка'
        case 125:
            return 'Electrum'
        case 126:
            return 'Plategka'
        case 204:
        case 127:
            return 'City24'
        case 128:
            return 'Concord Bank'
        case 202:
            return 'Monobank'
        case 203:
            return 'BankFamilnuy'
        default:
            return ''
    }
}

export const checkPayStatus = function (status_id) {
    switch (parseInt(status_id)) {
        case 0:
            return this.$t('payment.table.statusType.processing')
        case 1:
            return this.$t('payment.table.statusType.processing')
        case 2:
            return this.$t('payment.table.statusType.end')
        case 3:
            return this.$t('payment.table.statusType.cancel')
        case 6:
            return this.$t('payment.table.statusType.error')
        default:
            return ''
    }
}

export const setMessageDate = function (date, isMobile) {
  const dateSend = new Date(date)
  const diffInMs = Math.abs(new Date() - dateSend)
  const minutes = Math.floor(diffInMs / (1000 * 60))
  const minutesInDay = 24 * 60

  if (minutes <= 60) return isMobile ? date.substring(11, 16) : this.$tc('minutes', minutes)
  else if (minutes > 60 && minutes <= minutesInDay) return date.substring(11, 16)
  else return date.substring(0, 10).replace(/-/g, '.')
}

export const formatDate = function (date) {
  let dd = date.getDate()
  if (dd < 10) dd = '0' + dd
  let mm = date.getMonth() + 1
  if (mm < 10) mm = '0' + mm
  let yy = date.getFullYear() % 100
  if (yy < 10) yy = '0' + yy
  return `${dd}.${mm}.${yy}`
}

export const copyUrl = text => {
    try {
      navigator.clipboard.writeText(text)
    } catch (e) {
      throw e
    }
  }

export const dateAfterDays = days => (new Date(new Date(Date.now() - ((new Date()).getTimezoneOffset()
  * 60000)).setDate(new Date(Date.now() - ((new Date()).getTimezoneOffset()
  * 60000)).getDate() + days))).toISOString().substr(0, 10)

export const getLocaleKey = key => {
  return key ? i18n.locale === 'ua' ? `${key}_ukr` : key : ''
}

export const formatMacAddress = input => {
  // Remove any non-hexadecimal characters
  const cleanInput = input.replace(/[^a-fA-F0-9]/g, '')

  // Ensure it's at most 12 characters long
  const trimmedInput = cleanInput.slice(0, 12)

  // Format into MAC address (XX:XX:XX:XX:XX:XX)
  return trimmedInput.match(/.{1,2}/g)?.join(':') || ''
}
