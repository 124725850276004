<template lang="pug">
  div
    v-data-table(
      :headers="headers"
      :items="items"
      :items-per-page="20"
      :loading="isLoad"
      loading-text="Завантаження..."
      no-results-text="Дані не знайдено"
      no-data-text="Даних немає"
      hide-default-footer
    )
      template(#item.created="{ item }")
        span.text--primary.b-600 {{ item.created_at }}
    v-pagination(
      v-if="pageCount > 1"
      v-model="currentPage"
      :length="pageCount"
      :total-visible="9"
      @input="changePage"
      class="mt-3"
    )
</template>

<script>
export default {
  name: 'Table',
  props: {
    headers:
    {
      type: Array,
      default: () => []
    },
    items:
    {
      type: Array,
      default: () => []
    },
    pageCount:
    {
      type: Number,
      default: 0
    },
    currentPage:
    {
      type: Number,
      default: 0
    },
    detailedRoute:
    {
      type: String,
      default: ''
    },
    changePage: {
      type: Function,
      default: null
    },
    isLoad: Boolean
  }
}
</script>

<style scoped>
  .search {
    width: 25rem;
    flex: none;
  }
</style>
