export default {
    state: () => ({
        items: [
            {
                id: 'bonusSystem',
                period: 'period',
                tag: 'tag',
                subtitle: 'subtitle',
                picture: 'Bonus-DiaNet-SITE-WEBP.webp',
                buttonName: 'buttonName',
                buttonLink: 'sales/bonus'
            },
            {
                id: '13months',
                period: 'period',
                tag: 'tag',
                subtitle: 'subtitle',
                picture_ru: 'month13_ru.webp',
                picture_ua: 'month13_ua.webp',
                buttonName: 'buttonName',
                buttonLink: 'sales/12-plus-1'
            }
        ]
    })
}
