import { paymentUrls } from '@/configs/endpoints'
import api from '@/plugins/api'

export default {
  state: () => ({
    payments: [],
    fees: [],
    paymentUrls: {},
    isLoad: false,
    paymentHistory: [],
    paymentMethods: []
  }),
  mutations: {
    SET_DATA_PAYMENT (state, data) {
      Object.entries(data).forEach( entry => {
        state[entry[0]] = entry[1]
      })
    }
  },
  actions: {
    async getPayments ({ commit }) {
      try {
        commit('SET_DATA_PAYMENT', { isLoad: true })
        const response = await api.get(paymentUrls.get_payments)
        commit('SET_DATA_PAYMENT', { payments: response.data })
        commit('SET_DATA_PAYMENT', { isLoad: false })
      } catch (error) {
        console.log(error)
      } finally {
        commit('SET_DATA_PAYMENT', { isLoad: false })
      }
    },
    async getFees ({ commit }) {
      try {
        commit('SET_DATA_PAYMENT', { isLoad: true })
        const response = await api.get(paymentUrls.get_fees)
        commit('SET_DATA_PAYMENT', { fees: response.data })
        commit('SET_DATA_PAYMENT', { isLoad: false })
      } catch (error) {
        console.log(error)
      } finally {
        commit('SET_DATA_PAYMENT', { isLoad: false })
      }
    },
    async getPaymentMethods ({ commit }) {
      try {
        const response = await api.get(paymentUrls.payment_methods + '?cabinet_type=web&is_active=true')
        commit('SET_DATA_PAYMENT', { paymentMethods: response.data })
      } catch (error) {
        console.log(error)
      }
    },
    async getPaymentUrls ({ commit }) {
      try {
        const response = await api.get(paymentUrls.payment_urls)
        commit('SET_DATA_PAYMENT', { paymentUrls: response.data })
      } catch (error) {
        console.log(error)
      }
    },
    async getLiqpay (context, body) {
      try {
        const response = await api.post(paymentUrls.liqpay_url, { body })
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async setPrivatInvoice (context, body) {
      try {
        const response = await api.post(paymentUrls.privat, { body })
        return response.data
      } catch (error) {
        console.log(error)
        return error
      }
    },
    async getMonopay (context, body) {
      try {
        const response = await api.post(paymentUrls.monopay_url, { body })
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async getPaymentHistory ({ commit, rootState }) {
      try {
        const response = await api.post(paymentUrls.get_payment_history, { 
          body: {
            payment_history: 1,
            uid: rootState.user.userInfo.uid
          } 
        })
        commit('SET_DATA_PAYMENT', { paymentHistory: response.data })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
