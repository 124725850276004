<template lang="pug">
  v-main.h-full(:class="{'dark-theme': darkTheme}")
    Header
    //- v-app(v-if="isAdmin")
    //-   LoadAdmin
    v-app(v-if="isGuestRoute || !isAuth() || $route.name === 'choose-account'")
      //- | isGuestRoute= {{isGuestRoute}} !isAuth= {{!isAuth}} $route.name === 'choose-account'= {{$route.name === 'choose-account'}}
      router-view
    v-app(v-else)
      template(v-if="isAuth && !login")
        SideBar(v-show="login")
        Hello
      div(v-else)
        SideBar
        .content-body
          router-view
          ModalFeedback(v-if="!isAdmin" :is-open="isNeedPoll")
    Footer
    ModalApp(v-if="isShowModalApp" @emitModal="modalState = $emit")

</template>

<script>
import { mapState, mapActions } from 'vuex'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import SideBar from '@/components/SideBar'
import Hello from '@/views/Hello'
import LoadAdmin from '@/views/LoadAdmin'
import ModalFeedback from '@/components/Modals/ModalFeedback'
import ModalApp from '@/components/Modals/ModalApp.vue'


import '@/assets/css/style.css'
import '@/assets/sass/main.sass'
import '@/assets/sass/components/dashboard.sass'


export default {
  name: 'App',
  components: {
    ModalFeedback,
    SideBar,
    Header,
    Footer,
    ModalApp,
    Hello,
    LoadAdmin
  },
  data () {
    return {
      isGuestRoute: true,
      // isAdmin: window.location.href.includes('admin_login'),
      modalState: sessionStorage.getItem('modalApp') || sessionStorage.setItem('modalApp', 'show')
    }
  },
  computed: {
    ...mapState({
      isNeedPoll: state => state.user.is_ready_to_poll,
      login: state => state.user.userInfo.login,
      windowWidth: state => state.app.windowWidth,
      darkTheme: state => state.profile.isDarkTheme,
      isShowModalApp () {
        return this.windowWidth <= 600 && this.modalState === 'show'
      },
      isAdmin () {
        return this.$store.getters.isAdmin()
      }
    })
  },
  watch: {
    '$route' (route) {
      this.isGuestRoute = route.meta.guest || false
    },
    '$i18n.locale' () {
      document.title = this.$t('metaTitle')
    }
  },
  async mounted () {
    if (window.location.href.includes('admin_login')){
      await this.authAdmin(window.location.href.split('/admin_login/')[1])
      this.getUserInfo()
      this.getClientProfile()
      this.getUserListAccount('list_account')
      this.getMessages()
      this.$router.push({ name: 'main' })
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
	},
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions(['changeWidth', 'authAdmin', 'getUserInfo', 'getClientProfile',
      'getUserListAccount', 'getMessages']),
    onResize () {
      this.changeWidth(window.innerWidth)
    },
    isAuth () {
      return this.$auth.isAuth()
    }
  }
};
</script>
