import { bonusesUrls } from '@/configs/endpoints'
import api from '@/plugins/api'

export default {
	state: () => ({
		bonusData: undefined,
		bonusesConditions: [],
		bonusesReferralUrl: '',
		isLoading: false,
		achievements: []
	}),
	mutations: {
		SET_DATA_BONUSES (state, data) {
			Object.entries(data).forEach(entry => {
				state[entry[0]] = entry[1]
			})
		}
	},
	actions: {
		async getBonuses ({ commit }) {
			try {
				commit('SET_LOAD', true)
				const response = await api.get(bonusesUrls.bonus_balance)
				commit('SET_DATA_BONUSES', { bonusData: response.data })
				commit('SET_LOAD', false)
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_LOAD', false)
			}
		},
		async getBonusesConditions ({ commit }) {
			try {
				commit('SET_DATA_BONUSES', { isLoading: true })
				const response = await api.get(
					`${bonusesUrls.loyalty_program}?type=conditions`
				)
				commit('SET_DATA_BONUSES', { bonusesConditions: response.data.results })
				commit('SET_DATA_BONUSES', { isLoading: false })
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_BONUSES', { isLoading: false })
			}
		},
		async getBonusesReferralUrl ({ commit }) {
			try {
				commit('SET_DATA_BONUSES', { isLoading: true })
				const response = await api.get(bonusesUrls.referral_url)
				commit('SET_DATA_BONUSES', {
					bonusesReferralUrl: response.data.short_url
				})
				commit('SET_DATA_BONUSES', { isLoading: false })
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_BONUSES', { isLoading: false })
			}
		},
		async activateBonuses ({ commit }, payload) {
			try {
				commit('SET_DATA_BONUSES', { isLoading: true })
				await api.patch(bonusesUrls.bonus_balance, payload)
				commit('SET_DATA_BONUSES', { isLoading: false })
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_BONUSES', { isLoading: false })
			}
		},
		async transferBonusesToBill ({ commit }, payload) {
			try {
				commit('SET_DATA_BONUSES', { isLoading: true })
				await api.patch(bonusesUrls.transfer_bonuses_to_bill, payload)
				commit('SET_DATA_BONUSES', { isLoading: false })
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_BONUSES', { isLoading: false })
			}
		},
		async getAchievements ({ commit }) {
			try {
				commit('SET_DATA_BONUSES', { isLoading: true })
        const responseAchievements = await api.get(bonusesUrls.achievement)
        const responseClientAchievements = await api.get(bonusesUrls.client_achievement)
        const clientAchievements = responseClientAchievements.data.results
        const achievements = responseAchievements.data.results.map(item => {
          const clientAchievement = clientAchievements.find(clientAchive => clientAchive.achievement === item.id)

          if (clientAchievement) return {
            ...item,
            bonuses: item.bonuses[0]?.level !== 1 ? item.bonuses.reverse() : item.bonuses,
            isClient: true,
            bonus: clientAchievement.bonus,
            is_completed: clientAchievement.is_completed,
            isFirstLevelComplete: clientAchievement.bonus?.level 
            !== item.bonuses[item.bonuses.length - 1]?.level 
            && clientAchievement.progress > parseInt(clientAchievement.bonus?.requirement),
            progress: clientAchievement.progress
          }
          else return {
            ...item,
            bonuses: item.bonuses[0]?.level !== 1 ? item.bonuses.reverse() : item.bonuses,
            isClient: false,
            isFirstLevelComplete: false
          }
        })
        commit('SET_DATA_BONUSES', { achievements })
				commit('SET_DATA_BONUSES', { isLoading: false })
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_BONUSES', { isLoading: false })
			}
		}
	},
  getters: {
    clientAchievements: state => state.achievements.filter(item => item.isClient),
    notReceivedAchievements: state => state.achievements.filter(item => !item.is_completed)
  }
}
