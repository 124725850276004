import { tariffUrls } from '@/configs/endpoints'
import api from '@/plugins/api'

export default {
  state: () => ({
    tariffs: [],
    currentTariff: undefined,
    tariffCosts: {},
    ip: undefined,
    isLoading: false
  }),
  mutations: {
    SET_DATA_TARIFFS (state, data) {
      Object.entries(data).forEach(entry =>{
        state[entry[0]] = entry[1]
      })
    }
  },
  actions: {
    async getTariffs ({ commit }) {
      try {
        commit('SET_DATA_TARIFFS', { isLoading: true })

        const response = await api.get(tariffUrls.get_tarif)

        const baseIconsNames = ['antena', 'sattelite', 'spacesheep', 'shuttle']
        const tariffs = response.data.map((tariff, index) => ({
          ...tariff,
          icon: `${tariff.group_id === 1 ? 'house' : 'flat'}-${baseIconsNames[index]}.webp`
        }))
        const currentTariff = tariffs.find(tariff => tariff.is_current)
        commit('SET_DATA_TARIFFS', { tariffs, currentTariff })
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_DATA_TARIFFS', { isLoading: false })
      }
    },
    async getIPInfo ({ commit }) {
      const response = await api.get(tariffUrls.get_ip_info)
      commit('SET_DATA_TARIFFS', { ip: response.data[0] })
    },
    async setIP ({ rootState }) {
      const response = await api.post(tariffUrls.set_ip, {body: { uid: rootState.user.userInfo.uid}})
      return response.data
    },
    async deleteIP ({ rootState }) {
      const response = await api.post(tariffUrls.delete_ip, {body: { uid: rootState.user.userInfo.uid}})
      return response.data
    },
    async freezeAccount ({ rootState }, payload) {
      const response = await api.post(tariffUrls.freeze_unfreeze, {
        body: {
          point: rootState.user.userInfo.uid, date_start: payload[0],
          date_end: payload[1],
          type_service: 1
        }
      })
      if (response.code === 400) {
        const error = Object.entries((await response.data.json()))[0]
        const formedError = {
          field: error[0]
        }
        switch (error[1][0]) {
          case 'This field is required.':
            formedError.error = 'required'
            break
          case 'Freeze must start from tomorrow':
            formedError.error = 'startTomorrow'
            break
          case 'Must begin at least 3 days later than the date start':
            formedError.error = 'laterDateStart'
            break
          case 'Lack of funds on the client balance':
            formedError.error = 'lackClientBalance'
        }
        throw formedError
      } else if (!response.data.success){
        formedError.error = 'commonError'
        throw formedError
      }
    },
    async unfreezeAccount ({ rootState }) {
      const response = await api.delete(tariffUrls.freeze_unfreeze, {
        body: {
          point: rootState.user.userInfo.uid, type_service: 1
        }
      })
      return response.data
    },
    async getMegogoUpdateInformation (context, payload) {
      return await api.post(tariffUrls.check_ability_change_megogo_tariff, { body: payload })
    },
    async postTariff (context, payload) {
      const response = await api.post(tariffUrls.change_tarif, {body: payload})
      return response.data
    },
    async setCredit ({ rootState }) {
      const response = await api.post(tariffUrls.set_credit, {body: { uid: rootState.user.userInfo.uid, days: 3}})
      return response.data
    },
    async getTariffCosts ({ commit }) {
      const response = await api.get(tariffUrls.tariff_cost)
      commit('SET_DATA_TARIFFS', { tariffCosts: response.data })
    }
  },
  getters: {
    getTariffName: state => lang => {
      if (!state.currentTariff) return ''
      if ( lang === 'ru' ) return state.currentTariff.name
      return state.currentTariff.name_ukr
    },
    getTariffDesc: state => (tarifId, lang) => {
      const tarif = state.tariffs.find(item => item.tp_id === tarifId)
      if (!state.tariffs.length || !tarif) return ''
      if ( lang === 'ua' ) return tarif.description_ukr
      return (tarif.description ?  tarif.description  : 'Для оптимального доступу в Інтернет')
    },
    canGetCredit: (state, getters, rootState) => () => {
      return rootState.user.userInfo.can_get_credit
        && rootState.user.userInfo.credit === 0 && rootState.user.userInfo.credit_date === -1
    }
  }
}
