import { clientProfile } from '@/configs/endpoints'
import api from '@/plugins/api'

export default {
	state: () => ({
		isLoad: false,
    socials: [],
    clientSocials: [],
    defaultAvatars: [],
    isDarkTheme: JSON.parse(localStorage.getItem('isDarkTheme'))
	}),
	mutations: {
		SET_DATA_PROFILE (state, data) {
			Object.entries(data).forEach( entry => {
				state[entry[0]] = entry[1]
			})
		}
	},
	actions: {
		async getSocials ({ commit }) {
			try {
				commit('SET_DATA_PROFILE', { isLoad: true })
				const response = await api.get(clientProfile.social)
				if (response.data && response.data.length){
					const photos = []
					for await (let photo of response.data){
						const responsePhoto = await api.get(photo.photo, { responseType: 'blob' })
						if ( [200, 201].includes(responsePhoto.code) ) 
						{
							photos.push({ 
							...photo,
							file:  window.URL.createObjectURL(new File(
								[responsePhoto.data], 
								'file_name',
								{ lastModified: new Date(), type: responsePhoto.data.type }))
							})
						}
					}
					commit('SET_DATA_PROFILE', { socials: photos })
				}
				commit('SET_DATA_PROFILE', { isLoad: false })
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_PROFILE', { isLoad: false })
			}
		},
		async getClientSocials ({ commit }) {
			try {
				commit('SET_DATA_PROFILE', { isLoad: true })
				const response = await api.get(clientProfile.clientSocial)
        		commit('SET_DATA_PROFILE', { clientSocials: response.data })
				commit('SET_DATA_PROFILE', { isLoad: false })
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_PROFILE', { isLoad: false })
			}
		},
		async addClientSocial ({ commit }, payload) {
			try {
				commit('SET_DATA_PROFILE', { isLoad: true })
				await api.post(clientProfile.clientSocial, payload)
				commit('SET_DATA_PROFILE', { isLoad: false })
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_PROFILE', { isLoad: false })
			}
		},
		async deleteClientSocial ({ commit }, id) {
			try {
				commit('SET_DATA_PROFILE', { isLoad: true })
				await api.delete(`${clientProfile.clientSocial}${id}/`)
				commit('SET_DATA_PROFILE', { isLoad: false })
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_PROFILE', { isLoad: false })
			}
		},
		async postPhone ({ commit }, payload) {
			try {
				commit('SET_DATA_PROFILE', { isLoad: true })
				const response = await api.post(clientProfile.userContact, payload)
				commit('SET_DATA_PROFILE', { isLoad: false })
        return response.data
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_PROFILE', { isLoad: false })
			}
		},
		async resendVerifyCode ({ commit }, payload) {
			try {
				commit('SET_DATA_PROFILE', { isLoad: true })
				const response = await api.get(`${clientProfile.userContact}${payload}/resend_verify_code/`)
				commit('SET_DATA_PROFILE', { isLoad: false })
        console.log(response)
        return response
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_PROFILE', { isLoad: false })
			}
		},
		async verifyingCode ({ commit }, payload) {
			try {
				commit('SET_DATA_PROFILE', { isLoad: true })
				const response = await api.post(`${clientProfile.userContact}${payload.id}/verify/`, payload.body)
				commit('SET_DATA_PROFILE', { isLoad: false })
        return response.data
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_PROFILE', { isLoad: false })
			}
		},
    async getDefaultAvatars ({ commit }) {
      try {
		commit('SET_DATA_PROFILE', { isLoad: true })
		const response = await api.get(clientProfile.avatar)
		if (response.data && response.data.length){
			const avatars = []
			for await (let photo of response.data){
				const responsePhoto = await api.get(photo.photo, { responseType: 'blob' })
				if ( [200, 201].includes(responsePhoto.code) ) 
				{
				 	avatars.push({ 
					...photo,
					file:  window.URL.createObjectURL(new File(
						[responsePhoto.data], 
						'file_name',
						{ lastModified: new Date(), type: responsePhoto.data.type }))
					})
				}
			}
			commit('SET_DATA_PROFILE', { defaultAvatars: avatars })
		}
		commit('SET_DATA_PROFILE', { isLoad: false })
		} catch (error) {
			console.log(error)
		} finally {
			commit('SET_DATA_PROFILE', { isLoad: false })
		}
    },
    async sendAvatar ({ commit }, payload) {
      try {
				commit('SET_DATA_PROFILE', { isLoad: true })
				const response = await api.post(clientProfile.clientAvatar, payload)
				commit('SET_DATA_PROFILE', { isLoad: false })
        return response
			} catch (error) {
				console.log(error)
			} finally {
				commit('SET_DATA_PROFILE', { isLoad: false })
			}
    }
	},
  getters: {
    formedSocials: state => () => {
      if (state.clientSocials.length) {
        return state.socials.map(item => {
          const findSocial = state.clientSocials.find(findItem => findItem.social_network === item.id)
          if (findSocial) return {
              ...item,
              url: findSocial.url,
              clientId: findSocial.id
            }
          else return { ...item }
        })
      } else return state.socials
    }
  }
}