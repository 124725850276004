import { tvUrls } from '@/configs/endpoints'
import api from '@/plugins/api'
import { tvIconByTpId } from '@/configs/constants'


export default {
  state: () => ({
    statusTv: {},
    tariffTv: [],
    channelsTv: {},
    myDevices: [],
    advanced: [],
    sweetLink: '',
    isLoading: '',
    trinityPassword: '',
    tvTariffPlans: ['sweetTv', 'megogo', 'bothVariants']
  }),
  mutations: {
    SET_DATA_TV (state, data) {
      Object.entries(data).forEach(entry =>{
        state[entry[0]] = entry[1]
      })
    }
  },
  actions: {
    async changeStatusTV () {
      const response = await api.post(tvUrls.change_status_tv)
      return response.data
    },
    async getTVInfo ({ commit }) {
      const response = await api.get(tvUrls.get_tv_info)
      if (response?.code === 200) {
        Object.keys(response.data).forEach(key => {
          const defaultTariffIcon = key === 'megogo' ? 'megogo.webp' : 'promo.webp'
          response.data[key].icon = tvIconByTpId[response.data[key].tariff_plan_tp_id] || defaultTariffIcon
        })
        commit('SET_DATA_TV', { statusTv: response.data })
      }
      return response
    },
    async getTVTariff ({ commit }) {
      try {
        commit('SET_DATA_TV', { isLoading: true })
        const response = await api.get(tvUrls.get_tv_tariff)
        const tariffTv = response.data.map(tvTariff => {
          const defaultTariffIcon = tvTariff.service === 'Megogo' ? 'megogo.webp' : 'promo.webp'
          return {
            ...tvTariff,
            icon: tvIconByTpId[tvTariff.tp_id] || defaultTariffIcon
          }
        })
        commit('SET_DATA_TV', { tariffTv })
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_DATA_TV', { isLoading: false })
      }
    },
    async getTVChannels ({ commit }) {
      try {
        commit('SET_DATA_TV', { isLoading: true })
        const response = await api.get(tvUrls.get_tv_channels)
        commit('SET_DATA_TV', { channelsTv: response.data })
        commit('SET_DATA_TV', { isLoading: false })
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_DATA_TV', { isLoading: false })
      }

    },
    async getTVTrinityInfo ({ commit }) {
      const response = await api.get(tvUrls.get_tv_trinity_info)

      if(response.data.length && response.data[0].login_code) {
        commit('SET_DATA_TV', { trinityPassword: response.data[0].login_code })
      }
    },
    async getScreens ({ commit }) {
      commit('SET_DATA_TV', { isLoading: true })
      const response = await api.get(tvUrls.get_screens)
      response.data.map(item => {
        item.typeDevice = { class: '', title: '' }
        switch (item.type) {
          case '1':
          case 1:
            item.icon = require('@/assets/images/screens/tv.svg') //TV
            break
          case '2':
          case 2:
            item.icon = require('@/assets/images/screens/tv-box.svg') //TV-приставка
            break
          case '3':
          case 3:
            item.icon = require('@/assets/images/screens/mobile.svg') //Телефон
            break
          case '4':
          case 4:
            item.icon = require('@/assets/images/screens/tablet.svg') //Планшет
            break
          case '5':
          case 5:
            item.icon = require('@/assets/images/screens/laptop.svg') //Компьютер
        }
      })
      commit('SET_DATA_TV', { myDevices: response.data, isLoading: false })
    },
    async removeScreen ({commit, state}, payload) {
      try{
        const response = await api.post(tvUrls.remove_screen, { body: payload })
        if(response.data.status === 'success') {
          commit('SET_DATA_TV', { myDevices: state.myDevices.filter(item => item.id !== payload.id) })
          return true
        } else {
          console.log(response)
          return false
        }
      } catch(error){
        console.log(error)
      }
    },
    async getAdvanced ({ commit }) {
      const response = await api.get(tvUrls.get_tv_tariff)
      commit('SET_DATA_TV', { advanced: response.data })
    },
    async buyAdvanced (context, payload) {
      await api.post(tvUrls.buy_tv_tariff, {body: payload})
    },
    async deleteAdvanced () {
      await api.post(tvUrls.remove_tv_tariff)
    },
    async addTvScreenPlayList (context, payload) {
      try {
        return api.post(tvUrls.add_playlist, { body: payload })
      } catch (error) {
        console.log(error)
      }
    },
    async addTvScreenPost (context, payload) {
      try {
        return api.post(tvUrls.add_screen, { body: payload })
      } catch (error) {
        console.error('Error while new device adding:', error)
      }
    },
    async getSweetTVLink ({ commit }, payload) {
      try {
        const response = await api.post(tvUrls.get_site_access_link, {body: payload})
        if(response.code < 200 || response.code > 299) throw new Error()
        commit('SET_DATA_TV', { sweetLink: response.data })
      } catch (error) {
        console.log(error)
      }
    },
    async changeTvAccountPassword (context, payload) {
      try {
        return await api.post(tvUrls.change_megogo_credentials, { body: payload })
      } catch (error) {
        console.error('Failed to change tv account password:', error)
      }
    }
  },
  getters: {
    getTVName: state => lang => {
      if (!state.statusTv) return ''
      if ( lang === 'ru' ) return state.statusTv.tariff_plan
      return state.statusTv.tariff_plan_ukr
    },

    devicesDayFee: state => {
      const { next_periodic, periodic } = state.statusTv.trinity || {}
      if (!next_periodic || !periodic || state.myDevices.length < 4) return 0
      return next_periodic.periodic_price - periodic.periodic_price
    }
  }
}
