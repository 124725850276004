<template lang="pug">
  Hello
</template>

<script>
import { mapActions } from 'vuex'
import Hello from '@/views/Hello'
export default {
  name: 'LoadAdmin',
  components: {
    Hello
  },
  async mounted () {
      await this.authAdmin(window.location.href.split('/admin_login/')[1])
      await this.getUserInfo()
      this.getClientProfile()
      this.getUserListAccount('list_account')
      this.getMessages()
      //this.$router.push({ name: 'main' })
      //window.location.href= '/'
  },
  methods: {
    ...mapActions(['authAdmin', 'getUserInfo', 'getClientProfile', 'getUserListAccount', 'getMessages'])
  }
}
</script>
