<template lang="pug">
  #sideBar.nk-sidebar(
    :class="{'showBar': isShowSideBar, 'nk-sidebar-dropdown--active': isShowAccounts}"
    v-click-outside="{ handler: () => this.showSideBar({ isShowSideBar: false }), include: includedClickOutsideElement }"
  )
    .dropdown-account-mobile(v-if="uid")
      .current-account(v-if="getCurrentAccount" @click="isShowAccounts = !isShowAccounts")
        button.dropdown__btn(:class="!clientProfile.avatars?.length ? 'icon-login' : ''")
          img.dropdown__btn-img(v-if="clientProfile.avatars?.length" :src="clientProfile.file" width="30" height="30")
        span.dropdown__fio {{ fio }}
        i.icon-login(v-if="!getNotCurrentAccounts")
        i.icon-arrow-down(v-else :class="{ 'icon-arrow-down--active': isShowAccounts }")
      .available-accounts(:style="{'height': `${getAvailableAccountsHeight}px`}")
        template(v-for="account of getNotCurrentAccounts")
          .available-account(v-if="getNotCurrentAccounts")
            button.available-account__btn(@click="changeAccount(account.login)")
              i.icon-login
              | {{ account.address || $t('modal.addressHide')}}
            button.icon-trash(@click="logoutAnotherAcc(account.login)")
        .available-account
          button.flex-row-center.available-account__btn(v-if="getCurrentAccount" @click="isActiveModal = !isActiveModal")
            i.icon-add
            | {{ $t('modal.addAccount') }}
        .available-account
          button.flex-row-center.available-account__btn(@click="logout")
            i.icon-logout
            | {{ $t('header.logout') }}
      .divider
    AddAccount(:is-open.sync="isActiveModal")
    .nk-nav-scroll
      vs-collapse(accordion)
        vs-collapse-item(
          v-for="(item, index) in menu"
          :key="index"
          @click.native="changePage(item.path)"
          :class="['mega-menu-sm', isActiveLink(item) && 'activeMenu']"
          not-arrow)
          template(v-if="item.subItmes")
            li(slot="header")
              .has-arrow(v-if="item.name === 'tv'" tag="button").d-flex.align-items-center
                .sideBarImg(:class="item.iconClass")
                span.nav-text {{$t(`sidebar.${item.name}`)}}
              button.has-arrow.d-flex.align-items-center(v-else)
                .sideBarImg(:class="item.iconClass")
                span.nav-text {{$t(`sidebar.${item.name}`)}}
            ul
              li(v-for="sub, subIndex in item.subItmes").has-parent.toggle-menu
                .subMenu(v-if="sub.path").subMenu
                  span.nav-text1 {{$t(`sidebar.${sub.name}`)}}
                a.subMenu(v-else-if="sub.link || sweetLink" :href="sub.name === 'sweetTv' ? sweetLink : sub.link" target="_blank" @click="sub.name === 'sweetTv' ? setDataTv({sweetLink: 'https://sweet.tv/'}) : null")
                  span.nav-text1 {{$t(`sidebar.${sub.name}`)}}
          template(v-else)
            li(slot="header")
              .d-flex.align-items-center
                .sideBarImg(:class="item.iconClass")
                span.nav-text {{$t(`sidebar.${item.name}`)}}
    .sideBarBottom
      SideQR
      a.detailBtn.sideBarSiteBtn(:href="getButtonLink" target='_blank') {{ $t('sidebar.site') }}
        .arrowImg
    .divider
    Language.sideBarLang
    .flex-row-center.switch-theme
      .d-flex.align-items-center
        i.icon-edit.mr-4
        | {{ $t('header.theme') }}
      v-switch.mt-0.pt-0.theme-switcher(v-model="isDarkTheme" @change="setTheme" hide-details inset dark dense)
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import SideQR from './SideQR.vue'
import AddAccount from './Modals/AddAccount.vue'
import Language from './Language.vue'
import '@/assets/sass/components/sidebar.sass'

export default {
    name: 'SideBar',
    components: {
      SideQR,
      AddAccount,
      Language
    },
    data () {
        return {
            menu: [],
            className: 'mdi-home-variant',
            isActiveModal: false,
            isShowAccounts: false,
            isDarkTheme: false
        }
    },
    computed: {
        ...mapState({
            sweetLink: state => state.tv.sweetLink,
            isShowSideBar: state => state.app.isShowSideBar,
            clientProfile: state => state.user.clientProfile,
            userListAccount: state => state.user.accounts,
            fio: state => state.user.userInfo.fio,
            uid: state => state.user.userInfo.uid,
            darkTheme: state => state.profile.isDarkTheme
        }),
        getButtonLink () {
            return `${process.env.VUE_APP_SITE}${this.$i18n.locale === 'ru' ? 'ru/' : ''}`
        },
        getCurrentAccount () {
            return this.userListAccount?.find( account => account.curr)
        },
        getNotCurrentAccounts () {
            return this.userListAccount?.filter( account => !account.curr)
        },
        getAvailableAccountsHeight () {
            return this.isShowAccounts && this.getNotCurrentAccounts ?
            ((this.getNotCurrentAccounts.length + 2) * 38) + 14
            : 0
        }
    },
    watch: {
      isShowSideBar (newVal) {
        if (!newVal) this.isShowAccounts = false
      },
      clientProfile (val) {
        const menu = [
          { path: '/', name: 'main', iconClass: 'home', exact: true },
          { path: '/payment', name: 'payment', iconClass: 'pay' },
          { path: '/bonuses', name: 'bonuses', iconClass: 'bonus' },
          { path: '/tariffs', name: 'tariffs', iconClass: 'tariff' },
          { path: '/tv', name: 'tv', iconClass: 'tariff-tv' },
          { path: '/support', name: 'support', iconClass: 'help-call'},
          { path: '/profile', name: 'profile', iconClass: 'profile'}
        ]
        if (val.type === 'legal') menu.splice(2, 3)
        this.$set(this, 'menu', menu)
      }
    },
    async mounted () {
        await this.getUserInfo()
        this.getPaymentMethods()
        this.getClientProfile()
        this.getUserListAccount('list_account')
        this.getMessages()
        this.isDarkTheme = this.darkTheme
        // this.getSweetTVLink({
        //     site_id: 2
        // }),
    },
    methods: {
        ...mapActions([
            'postChangeAccount',
            'getUserListAccount',
            'postLogoutAnotherAcc',
            'postAddAccount',
            'getUserInfo',
            'getClientProfile',
            'getMessages',
            'getSweetTVLink',
            'getPaymentMethods'
        ]),
        ...mapMutations({
            setDataTv: 'SET_DATA_TV',
            showSideBar: 'SET_APP',
            setDataProfile: 'SET_DATA_PROFILE'
        }),
        changePage (link){
            if (link && ('/' + this.$route.name !== link)) {
                this.$router.push(link)
                if (this.isShowSideBar) this.showSideBar({isShowSideBar : !this.isShowSideBar})
            }
        },
        logout () {
            this.$auth.logout()
            this.$router.go()
        },
        async changeAccount (login) {
			      await this.postChangeAccount({ body: { login } })
            this.$router.go()
		    },
        async logoutAnotherAcc (login) {
            await this.postLogoutAnotherAcc({ body: { login } })
            this.getUserListAccount('list_account')
		    },
        setTheme (e) {
          this.setDataProfile({ isDarkTheme: e })
          localStorage.setItem('isDarkTheme', e)
        },
      isActiveLink (link) {
        if (!link) return false
        return link.exact ? this.$route.path === link.path : this.$route.path.startsWith(link.path)
      },
      includedClickOutsideElement () {
        return [document.querySelector('.nav-header')]
      }
    }
}
</script>
