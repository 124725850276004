<template lang="pug">
v-dialog(v-model='isOpen' @click:outside='close' content-class='modal-app__content')
  v-card
    .modal-app
      button.modal-app__close-btn(@click='close')
      .modal-app__title
        | {{ $t('modal.app.title') }}
      ul.modal-app__benefits
        li {{ $t('modal.app.outOfMoneyMessage') }}
        li {{ $t('modal.app.appAuthorization') }}
        li {{ $t('modal.app.payment') }}
        li {{ $t('modal.app.serviceManagement') }}
      .modal-app__link
        a(v-if='ios' @click='close' href='https://apps.apple.com/ua/app/%D0%BC%D0%BE%D0%B9-dianet/id1485105231?l=ru')
          img(v-if="$i18n.locale === 'ru'" src='@/assets/images/rus_img/download_app_store.svg' alt='')
          img(v-else src='@/assets/images/ukr_img/download_app_store_ukr.svg' alt='')
        a(v-else @click='close' href='https://play.google.com/store/apps/details?id=com.dianet.mydianet&hl=https://play.google.com/store/apps/details?id=com.ua.mytrinity.dianettv&hl=ru')
          img(v-if="$i18n.locale === 'ru'" src='@/assets/images/rus_img/download_google_play.svg' alt='')
          img(v-else src='@/assets/images/ukr_img/download_google_play.svg' alt='')
</template>

<script>
export default {
	name: 'ModalApp',
	data () {
		return {
			isOpen: true,
			ios: this.$device.ios
		}
	},
  mounted () {
    window.addEventListener('resize', this.onResize)
  },
	methods: {
		close () {
			sessionStorage.setItem('modalApp', 'hide')
			this.$emit('emitModal', 'hide')
		},
    onResize () {
      this.windowWidth = window.innerWidth
    }
	}
}
</script>
