import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import app from './modules/app'
import user from './modules/userData'
import payment from './modules/payment'
import tariffs from './modules/tariffs'
import tv from './modules/tv'
import messages from './modules/messages'
import slider from './modules/slider'
import bonuses from './modules/bonuses'
import profile from './modules/profile'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

const store = new Vuex.Store({
  modules: {
    app,
    user,
    payment,
    tariffs,
    tv,
    messages,
    slider,
    bonuses,
    profile
  },
  plugins
})

export default store
