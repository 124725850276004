export const ForValidationOtherCountryPhone = [
    {
        value_abbr: 'UA',
        length: 13,
        dial_code: '+38 0',
        mask: '## ### ## ##'
    }
]

export const phoneNumbers = [
  {'0800304114': '0 800 30 41 14'},
  {'+380687074114': '068'},
  {'+380737074114': '073'},
  {'+380507074114': '050'},
  {'+380617074114': '061'}
]

export const socialLinks = [
  {
    img: 'icon-instagram.svg',
    link: 'https://www.instagram.com/dianet.official/',
    alt: 'altInstagram'
  },
  {
    img: 'icon-facebook.svg',
    link: 'https://www.facebook.com/dianet.official',
    alt: 'altFacebook'
  },
  {
    img: 'icon-viber.svg',
    link: 'viber://pa?chatURI=dianet',
    alt: 'altViber'
  },
  {
    img: 'icon-telegram.svg',
    link: 'https://t.me/DiaNet_bot',
    alt: 'altTelegram'
  }
]

export const emails = {
  support: 'support@dianet.online'
}

// export const paySystems =
// ['ApplePay', 'Privat24', 'EasyPay', 'Liqpay', 'Google Pay']

export const paySystems = ['Privat24', 'EasyPay', 'Liqpay', 'MonoPay'] //'Запорожсвязьсервис'

export const payPrice = [100, 200, 500]

export const mapLink = 'https://map.dianet.online/'

export const officeCoordinates = {
  left: {
    lat: '47.781142',
    lan: '35.20635499999999'
  },
  right: {
    lat: '47.87595',
    lan: '35.062208'
  }
}

export const whiteIps = [
  'Fiz Real IP',
  'Free Real IP',
  'RealIP за гривну/день',
  'Real_ip_50_per_month',
  'UR Real IP',
  'Real IP'
]

export const tvProviders = {
  sweetTv: 'Trinity',
  megogo: 'Megogo'
}

export const promoTariff = {
  count_channels: 108,
  fees: { day_fee: 0, month_fee: 0, clear_price: 0, rrp: 0 },
  name: 'Промо',
  name_ukr: 'Промо',
  service: 'Trinity',
  icon: 'promo.webp',
  tp_id: process.env.VUE_APP_IS_PRODUCTION === 'true' ? 92 : 15
}

export const tvChannelsByTpId = {
  92: 'promo',
  15: 'promo',
  20: 'base',
  23: 'standart',
  24: 'premium',
  36: 'megogo_light',
  37: 'megogo_optimal',
  38: 'megogo_maximum'
}

export const tvIconByTpId = {
  default: 'promo.webp',
  92: 'promo.webp',
  15: 'promo.webp',
  20: 'minimal.webp',
  23: 'middle.webp',
  24: 'max.webp',
  36: 'megogo.webp',
  37: 'megogo.webp',
  38: 'megogo.webp'
}

export const absentTariff = {
  tp_id: null,
  tariff_plan_tp_id: null,
  name_ukr: 'Не обрано',
  name: 'Не выбрано',
  count_channels: 0,
  fees: {
    month_fee: 0
  }
}
