<template lang="pug">
  include ../configs/templatesPUG/template.pug
  .hello
    Loader(is-content is-bg-transparent)
    .imgContainer
      img(src="@/assets/images/woman.png" alt="")
</template>

<script>
import '@/assets/sass/pages/Login.sass'
export default {
    name: 'Hello'
}
</script>
