<template lang="pug">
  div
    .qrSideBar(v-if="!isLoading")
      | {{ $t('sidebar.mobileApp') }}
      img.qrcode(:src="`data:image/png;base64, ${qrCodeForMobile}`" @click="isActiveModal = !isActiveModal")
      p {{ $t('sidebar.scanQR') }}
      ModalQR(:is-open.sync="isActiveModal" :qr="qrCodeForMobile")
    Loader.qrSideBar-loader(v-else) 
    a.detailBtn.sideBarSiteBtn.qrSideBar-mobile(@click="isActiveModal = !isActiveModal") {{ $t('sidebar.mobileApp') }}
      .arrowImg 
</template>
<script>
import ModalQR from '@/components/Modals/ModalQR'
import { mapState, mapActions } from 'vuex'
export default {
	name: 'SideQR',
	components: {
    ModalQR
	},
  data () {
    return {
      isActiveModal: false
    }
  },
	computed: {
		...mapState({
			isLoading: state => state.user.isLoading,
			qrCodeForMobile: state => state.user.qrCodeForMobile,
      windowWidth: state => state.app.windowWidth
		})
	},
	mounted () {
		if (!this.qrCodeForMobile) this.setQRCodeForMobile()
	},
	methods: {
		...mapActions(['setQRCodeForMobile'])
	}
}
</script>
