export default {
  state: () => ({
    isLoad: false,
    isShowSideBar: false,
    windowWidth: window.innerWidth
  }),
  mutations: {
    SET_LOAD (state, data) {
      state.isLoad = data
    },
    SET_APP (state, data) {
      Object.entries(data).forEach(entry =>{
        state[entry[0]] = entry[1]
    })
    },
    SET_WIDTH (state, data) {
      state.windowWidth = data
  }
  },
  actions: {
    async changeWidth ({ commit }, data) {
      commit('SET_WIDTH', data )
  }
  },
  getters: {

  }
}
