import { messagesUrls } from '@/configs/endpoints'
import api from '@/plugins/api'

export default {
	state: () => ({
		messagesData: {},
    isLoad: true,
    chosenMessage: null
	}),
	mutations: {
		SET_DATA_MESSAGES (state, data) {
			Object.entries(data).forEach( entry => {
				state[entry[0]] = entry[1]
			})
		}
	},
	actions: {
		async getMessages ({ commit }, params) {
			let response
			try {
				response = await api.get(params ? 
					`${messagesUrls.get_data}?is_archived=${params.isArchived}&page=${params.page}&page_size=${
          params.pageSize}&search=${params.search || ''}` : messagesUrls.get_data)
				commit('SET_DATA_MESSAGES', { messagesData: response.data })
			} catch (error) {
				console.log(error)
			}
			return response?.data
		},
		async readMessage (context, params) {
			try {
				await api.patch(`${messagesUrls.get_data}${params.id}/`, {
					body: {
						is_hidden: params.isHidden
					}
				})
			} catch (error) {
				console.log(error)
			}
		},
    async deleteMessage (context, params) {
      try {
        await api.delete(`${messagesUrls.get_data}${params.id}/`)
      } catch (error) {
        console.log(error)
      }
    },
    async archivedMessage (context, params) {
			try {
				await api.patch(`${messagesUrls.get_data}${params.id}/`, {
					body: {
						is_archived: params.isArchived
					}
				})
			} catch (error) {
				console.log(error)
			}
		}
	}
}
