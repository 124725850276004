export default {
  bankingFee: 'Платіжні системи можуть стягувати додаткову комісію.',
  metaTitle: 'Особистий кабінет | DiaNet',
  disableBonusesForLegal: 'Недоступно для юр. осіб',
  error: {
    notfound: 'Сторінку не знайдено',
    other: 'Виявлено помилку',
    serverError: 'Помилка сервера! Спробуйте будь-ласка пізніше',
    paymentError: 'Непередбачена помилка. Зверніться до служби підтримки.'
  },
  header: {
    language: 'Мова',
    logout: 'Вийти',
    theme: 'Тема',
    message: 'Повідомлення',
    messageNew: 'немає нових | {n} нове | {n} нових',
    messageMore: 'Більше...'
  },
  footer: {
    terms: 'Умови використання особистого кабінету'
  },
  login: {
    invalidUserTypeAuth: 'Авторизація за цим типом акаунту неможлива',
    authorization:'Авторизація',
    comeback: 'З поверненням! Будь ласка, введіть свої дані для входу.',
    accountAbsend: 'Ще немає облікового запису?',
    connect: 'Підключайтесь!',
    qrByPhoneEnter: 'QR-код для входу через смартфон',
    enterByNumber: 'Увійти за номером',
    enterByQrCode: 'Увійти за QR-кодом',
    requestCodeAgain: 'ЗАПРОСИТИ КОД ПОВТОРНО',
    phoneNumber: 'Номер телефону',
    enterCode: 'Введіть код',
    enterCodeFromSMS: 'Введіть код підтвердження, який надійде Вам в SMS-повідомленні, та натисніть "Продовжити".',
    codeEnter: 'Продовжити',
    title: 'Увійти',
    sendNewCode: 'Надіслати код ще раз',
    enterAnotherNumber: 'Ввести інший номер',
    password: 'Пароль',
    passwordConfirmation: 'Підтвердження паролю',
    login: 'Логін',
    resendCode:'Натисніть для відправки коду',
    codeResend: 'Код відтправлено повторно',
    codeSent: 'Код надіслано на номер: ',
    scanQr: 'Відскануйте цей QR-код у додатку «Мій DiaNet» для авторизації на сайті.',
    codeScanned: 'Код відскановано підтвердьте вхід на пристрою',
    codeExpired: 'Час дії коду вичерпано',
    authorized: 'Авторизовано',
    authorizationDenied: 'Авторизацію відхилено. Спробуйте ще раз',
    chooseAccount: 'Оберіть обліковий запис для входу:',
    error: {
      enterPhoneNumber: 'Введіть корректний номер телефону.',
      tooMutchRequests: 'Занадто багато спроб. Спробуйте ще раз згодом.',
      phoneLimit: 'Ліміт спроб вичерпано. Зверніться до оператора для уточнення коду.',
      oldCode: 'Код застарів (пройшло більше 30 хвилин), спробуйте знову',
      wrongCode: 'Некорректний код, спробуйте знову',
      wrongLoPass: 'Логін або пароль не вірні',
      limitHour: 'Спроби входу вичерпані. Спробуйте через 1 годину або зверніться у відділ підтримки',
      serverError: 'Помилка сервера! Спробуйте будь-ласка пізніше',
      authError: 'Помилка авторизації, спробуйте знову',
      tryAgain: 'Сталася помилка спробуйте пізніше'
    }
  },
  sidebar:{
    main: "Головна",
    payment: "Оплата",
    moneyOperation: "Грошові операції",
    paymentsHistory: "Історія оплат",
    feesHistory: "Історія списань",
    tariffs: "Тарифи",
    tv: "Телебачення",
    sweetTv: "Перегляд SWEET TV",
    trinityTv: "Перегляд TRINITY TV",
    support: "Підтримка",
    message: "Повідомлення",
    messageArchive: "Архів повідомлень",
    site: "Перейти на сайт",
    exit: "Вихід",
    bonuses: "Бонуси",
    profile: "Профіль",
    mobileApp: "Мобільний додаток",
    scanQR: 'Відскануйте QR-код'
  },
  modal: {
    attention: 'Зверніть увагу!',
    enterLogPas: 'Введіть логін та пароль',
    login: 'Логін',
    password: 'Пароль',
    addAccount: 'Додати акаунт',
    addressHide: 'Адреса прихована',
    invalidLoginPassword: 'Невірний логін та пароль, спробуйте ще раз',
    enterLoginPassword: 'Введіть логін та пароль',
    error: 'Виникла помилка. Спробуйте пізніше або зв\'яжіться з технічною підтримкою.',
    tryAgain: 'Спробуйте знову',
    edit: 'Редагувати персональні данні',
    addNumber: 'Додати ще один номер телефону',
    numberError: 'Будь-ласка дозаповніть номер телефону або видаліть його.',
    emailError: 'Будь-ласка введіть корректний email.',
    save: 'Зберегти',
    detailInfo: 'Детальна інформація',
    fio: 'П.І.Б:',
    number: 'Номер:',
    deleteIP: 'Відключити «Білу IP-адресу»?',
    addIP: 'Підключити «Білу IP-адресу»?',
    ipDescription: 'Послуга «Біла IP-адреса» дозволяє Вам отримати свій персональний статичний IP у мережі.',
    btnYes: 'Так',
    btnNo: 'Ні',
    ipChanged: 'IP адреса змінена',
    updated: 'Зміни внесено',
    freeze: 'Бажаєте заморозити свій рахунок?',
    unfreeze: 'Бажаєте розморозити свій рахунок?',
    freezeQueue: 'Заморозка рахунку в черзі',
    attention: 'Зверніть увагу!',
    freezeDescription: `Завдяки послузі «Заморозка» 
    Ви можете не платити повну вартість тарифу, коли не плануєте користуватись доступом до мережі Інтернет.`,
    freezeDone: 'Обліковий запис заморожено',
    earlierMinimalPeriodDescription: `При відключенні послуги «Заморозка рахунку» раніше мінімального терміну дії, 
    з Вас буде списана абонплата за 2 дні використання поточного тарифу.`,
    unfreezeDone: 'Обліковий запис разморожено',
    scheduledFreezeCanceled: 'Заплановане заморожування скасовано',
    servicePrice: 'Вартість послуги: <span>{price} грн/день</span>',
    ipWillChanged: `Ви впевнені, що хочете вимкнути послугу "Біла IP-адреса"? 
    Зверніть увагу: при повторній активації цієї послуги вашу IP-адресу буде змінено.`,
    minServiceUsing: 'Мінімальний термін користування послугою: <span>{days} дня</span>',
    withdrawalAmount: 'Сума списання становитиме: <span>{price} грн</span>',
    dayWithdrawalAmount: 'Буде списано денну абонплату у розмірі: <span>{price} грн</span>',
    sureTurnOffServiceEarlier: 'Ви впевнені, що хочете вимкнути послугу раніше часу?',
    turnOffServiceEarlier: 'Ви деактивуєте послугу «Заморозка рахунку» раніше встановленого періоду.',
    internetUnfreeze: `Доступ до мережі Інтернет буде відновлено, 
    а всі списання коштів будут здійснюватись згідно поточного тарифу.`,
    activatedAccountFreeze: 'Ви вже активували послугу "Заморозка рахунку". Вона почне діяти з обраної Вами дати.',
    cancelActivateFreeze: 'Наразі у Вас є можливість відминити активацію послуги або змінити вже обрані дати на інші.',
    setCredit: 'Активувати «Експрес-кредит»?',
    setCreditOnTime: 'Вам буде надано можливість користування  Інтернет-послугами в кредит на <span>3 дні</span>.',
    setCreditDate: 'Кредит діятиме до <span class="date">{date}</span>.',
    creditOk: 'Кредит надано',
    creditError: 'Помилка, зверніться до служби технічної підтримки',
    freezeErrors: {
      date_start: 'Дата початку заморозки',
      date_end: 'Дата кінця заморозки',
      required: 'поле обов`\язкове',
      startTomorrow: 'має початися із завтрашнього дня',
      laterDateStart: 'повинна починатися як мінімум на 3 дні пізніше дати початку',
      lackClientBalance: 'Недостатньо коштів на балансі',
      commonError: 'Помилка, зверніться до служби технічної підтримки'
    },
    app: {
      title: 'Завантажте додаток “Мій DiaNet”',
      outOfMoneyMessage: 'зручне отримання повідомлень про закінчення грошей на балансі',
      appAuthorization: 'авторизація через мобільний додаток',
      payment: 'зручна оплата',
      serviceManagement: 'управління послугами завжди під рукою'
    },
    notEnoughMoney: 'Зміна тарифу за від\'ємного балансу або під час дії послуги "Заморозка" заборонена.',
    notEnoughMoneyIP: `Підключення додаткових послуг при від\'ємному балансі або під час дії послуги 
      "Заморозка" заборонена.`,
    notEnoughMoneyDevice: 'Додавання пристроїв за від\'ємного балансу або під час дії Заморозки рахунку заборонено.',
    freezeForbidden: `Для активації послуги <span>"Заморозка"</span> 
    спочатку потрібно відключити активну послугу <span>"Біла IP-адреса"</span>.`
  },
  mBit: 'Мбіт/с',
  gBit: 'Гбіт/с',
  add: 'Додати',
  toCancel: 'Скасувати',
  cancel: 'Cкасування',
  apply: 'Застосувати',
  confirm: 'Підтвердити',
  close: 'Закрити',
  areYouSure: 'Ви впевнені?',
  required: `Обов'язкове поле`,
  details: 'Докладніше',
  search: 'Пошук',
  change: 'Змінити',
  delete: 'Видалити',
  connect: 'Підключити',
  turnOff: 'Відключити',
  conditions: 'Умови',
  reject: 'Відхилити',
  continue: 'Продовжити',
  mainPage:{
    login: 'Логін',
    detailInfo: 'Детальна інформація',
    address: 'Адреса:',
    balans: 'Баланс',
    tariff: 'Тариф',
    tv: "Телебачення",
    ip: 'IP адреса',
    pay: 'Поповнити',
    sale: 'Знижка',
    yearAction: 'Акція «12 + 1 місяць»',
    getAction: 'Підлкючити',
    connectedAction: 'Підключено',
    changeTariff: 'Змінити тариф',
    changeTariffForbidden: 'Низький баланс для зміни тарифу',
    tariffTV: 'Тариф TБ',
    tvDescription: 'Детальніше про ТБ',
    freeze: 'Заморожено',
    small_deposit: 'Маленький депозит',
    freeze_: 'Заморозити обліковий запис',
    unfreeze: 'Разморозити обліковий запис',
    credit: 'Кредит',
    setCredit: 'Експрес кредит',
    setCreditOnTime: 'Підключити кредит на 3 дні',
    cancelCredit: 'Відключити кредит',
    creditPeriod: 'Кредит діє до',
    payAccount: 'Поповнити'
  },
  slider: {
    buttonConnect: `Зв'язатись з нами`,
    list: {
      '13months': {
        period: 'Діє на постійній основі',
        title: '13 місяців у році',
        subtitle: 'Сплачуйте абонплату одразу за 12 місяців, та отримайте +1 місяць у подарунок!',
        tag: 'Акція «13 місяців в році»',
        buttonName: 'Докладніше'
      },
      bonusSystem: {
        period: 'Діє на постійній основі',
        title: 'Отримуйте Бонуси',
        subtitle: 'Користуйтесь послугами «DiaNet», отримуйте бонуси на свій рахунок та заощаджуйте кошти разом з нами!',
        tag: 'Приєднуйтесь до Програми Лояльності',
        buttonName: 'Докладніше'
      }
    }
  },
  dashBoards: {
    your_tariff: 'Ваш тариф',
    internet: 'Інтернет',
    tv: 'Телебачення',
    sweetTv: 'Тариф ТБ Sweet.tv',
    megogo: 'Підписка ТБ MEGOGO',
    pay: 'Вартість тарифу',
    pay_to: 'Сплачено до {date}',
    credit_to: 'Кредит до {date}',
    account_state: 'Статус',
    active: 'Активний',
    freeze: 'Заморожений',
    small_deposit: 'Маленький депозит',
    stop: 'Відключен',
    notActive: 'Неактивований',
    account: 'Логін',
    ip: 'IP-адреса',
    address: 'Адреса',
    bonuses: 'Бонуси',
    bonus_on_account: 'На рахунку',
    standing: 'Стаж',
    bonus_count: 'немає бонусів | бонус |  бонуси |  бонусів',
    year: '0 років| рік | роки | років',
    monthShort: 'міс.',
    month: '0 місяців | місяць | місяця | місяців',
    dataPayment: 'Дані для оплати',
    toPay: 'Сплатити',
    chooseAmount: 'Оберіть суму',
    enterYourValue: 'Внести своє значення',
    currentBalance: 'Поточна сума на рахунку:',
    balanceAfterPayment: 'Сума після поповнення:',
    expireDateAfterPayment: 'Коштів вистачить до:',
    paymentMethod: 'Платіжна система',
    personalData: 'Особисті дані',
    fio: 'П.І.Б',
    phoneNumber: 'Номер телефону',
    email: 'Електронна пошта',
    connectionInfo: 'Дані про підключення',
    connectionDate: 'Дата підключення',
    validity: 'Термін дії',
    tariff: 'Тариф',
    connectionAddress: 'Адреса підключення',
    region: 'Населений пункт',
    street: 'Вулиця',
    houseNumber: 'Номер будинку',
    flat: 'Квартира',
    socials: 'Соціальні мережі',
    connect: 'Підключити',
    addSocial: 'Додайте соцмережу',
    socialLink: 'Додайте посилання на свої соціальні мережі.',
    numberAlreadyAdded: 'Номер уже додано',
    codeSend: 'Код надіслано на номер:',
    addPhone: 'Додати номер',
    enterPhone: 'Будь ласка, вкажіть свій номер телефону.',
    numberConfirmation: 'Підтвердження номера',
    enterCode: 'Введіть код підтвердження, який було надіслано Вам у SMS-повідомленні та натисніть «Підтвердити».',
    smsCode: 'SMS-код',
    sendCodeAgain: 'Надіслати код ще раз',
    enterAnotherPhone: 'Ввести інший номер',
    successProfile: 'Ваші дані змінено успішно',
    success: 'Успіх',
    error: 'Помилка',
    priceList: 'Прайс лист послуг',
    compareTVTariffs: 'Порівняти тарифи ТБ',
    shopDianet: 'Магазин DiaNet'
  },
  yearAction:{
    title: '🎁 Акція «13 місяців у році»',
    text: 'Оплатіть свій тариф одразу за 12 місяців і отримайте знижку 8,33% на користування послугами на 360 днів',
    readAbout: 'Ознайомитися з умовами акції',
    connectionAction: 'Акція підключена',
    btnYes: 'Підключити',
    btnNo: 'Відхилити'
  },
  payment: {
    paymentTitle: 'Оплата',
    additional: {
      additionalTittle: 'Додатково',
      freeze: 'Заморозити рахунок',
      unfreeze: 'Розморозити рахунок',
      smallDeposit: 'Маленький депозит',
      waitFreeze: 'Очікує заморозку',
      addWhiteIp: 'Підключити «Білу IP-адресу»',
      deleteWhiteIp: 'Відключити «Білу IP-адресу»',
      helpUmf: 'Допомога ЗСУ'
    },
    historyPayments: 'Історія оплат',
    historyFees: 'Історія списань',
    status: 'Статус',
    table: {
      date: 'Дата',
      year: 'Рік',
      description: 'Опис',
      sum: 'Сума',
      paymentSystem: 'Платіжна система',
      transaction: 'Транзакція',
      status: 'Статус',
      deposit: 'Рахунок після поповнення',
      statusType: {
        processing: 'Обробка',
        end: 'Завершена',
        cancel: 'Відхилино',
        error: 'Помилка оплати'
      }
    }
  },
  bonusesQuantity: 'Без бонусів | {n} бонус | {n} бонуса | {n} бонусів',
  bonuses: {
    bonusesTitle: 'Бонуси',
    allAchievements: 'Усі нагороди',
    yourAchievements: 'Ваші нагороди',
    noAchievements: 'Доступні',
    activateBonuses: 'Активувати бонуси',
    activateBonusesDescription: 'Приєднуйтесь до Програми лояльності від «DiaNet» та отримуйте регулярні бонуси на свій рахунок! Ознайомтесь з детальними <a href="https://dianet.online/contracts#bonus" target="_blank">умовами</a> та почніть заощаджувати на послугах вже сьогодні.',
    activateBonusesAgree: 'Я ознайомлений(-а) з <a href="https://dianet.online/contracts#bonus" target="_blank">умовами</a> використання Програми лояльності та погоджуюся з ними.',
    withdrawMainAccount: 'Вивести на рахунок',
    withdrawBonuses: 'Бажаєте вивести бонуси?',
    withdrawBonusesDescription: `Ви виводите <span>{count} бонусів</span> на свій основний рахунок. 
    Баланс буде поповнено на <span>{count} грн</span>.`,
    referralProgram: 'Реферальна програма',
    referralInvite: 'Запросіть друга приєднатися до мережі «DiaNet» і обидва отримайте по 100 бонусів на свій рахунок.',
    referralInviteLink: 'Посилання-запрошення',
    referralCopied: 'Запрошення скопійовано',
    conditions: 'Умови використання',
    notHaveAchievements: 'У Вас поки що немає нагород!'
  },
  channel: 'немає каналів | {n} канал | {n} канали | {n} каналів',
  declinedChannel: 'немає каналів | канал | канали | каналів',
  tariffs: {
    tariffsTitle: 'Тарифи',
    yourTariff: 'Ваш тариф',
    whiteIpActive: '«Біла IP-адреса» підключена',
    whiteIpInactive: '«Біла IP-адреса» не підключена',
    discountActive: 'Знижка на тариф: -{discount}%',
    discountInactive: 'Знижок немає',
    freezingActive: 'Діє послуга «Заморозка рахунку»',
    freezingInactive: '«Заморозка рахунку» не активна',
    paidTvActive: 'Підключено платний тариф ТБ',
    paidTvInactive: 'Безкоштовний тариф ТБ',
    paidDevicesActive: 'Підключено платні пристрої',
    paidDevicesInactive: 'Немає платних пристроїв',
    tariffCost: `<span class="term__title">Вартість тарифу</span>
      <span class="term__quantity">{cost}<span> грн / 30 днів</span></span>`,
    internetSpeed: '<span class="term__title">Швидкість інтернету</span><span class="term__quantity">{speed}</span>',
    sweetTvChannels: `<span class="term__title">Тариф ТБ Sweet.tv</span>
      <span class="term__quantity">{channels}<span> каналів</span></span>`,
    megogoTvChannels: `<span class="term__title">Підписка ТБ MEGOGO</span>
      <span class="term__quantity">{channels}<span> каналів</span></span>`,
    tv: 'Кількість телеканалів',
    otherTariffs: 'Інші тарифи «DiaNet»',
    modal: {
      title: 'Бажаєте змінити тариф?',
      confirmTariffChange: 'Ви підтверджуєте зміну тарифу ТБ?',
      increase: 'збільшиться',
      decrease: 'зменшиться',
      same: 'залишиться',
      text: `Ви змінюєте свій тариф на <span class="tariff-orange">«{name}»</span>.<br> 
      Абонплата становитиме <span class="tariff-orange">{price} грн/день</span>, 
      а швидкість Інтернету <span class="tariff-grey">{upgrade}</span> 
      до <span class="tariff-orange">{speed}</span>.<br>
      Для перегляду TV буде доступно: <span class="tariff-grey">{channels}</span>.`,
      changeTariffOnce: 'Вибачте, але зміна тарифу можлива лише раз на добу.',
      tariffAlreadyChanged: `Виникли технічні проблеми, спробуйте будь-ласка пізніше 
      або зверніться до служби тезнічної підтримки`,
      tariffSuccessChanged: 'Тариф успішно змінено!',
      tariffFailedChangeTitle: 'Не вдалось змінити тариф!',
      tariffFailedChangeText: 'Щось пішло не так. Не вдалось змінити тариф. Повторіть спробу або спробуйте пізніше.'
    },
    plans: {
      sweetTv: 'Sweet.tv',
      megogo: 'MEGOGO',
      bothVariants: 'Обидва ТБ'
    },
    channelsList: 'Список каналів'
  },
  tariffsConstructor: {
    title: 'Конструктор тарифу',
    internet: {
      title: 'Інтернет',
      subtitle: 'Оберіть оптимальну швидкість інтернету для ваших потреб'
    },
    sweetTv: {
      title: 'Пакет ТБ Sweet.tv',
      subtitle: 'Розкрийте можливості з пакетом, створеним для Вас'
    },
    megogo: {
      title: 'Підписка ТБ MEGOGO',
      subtitle: 'Знаходьте ідеальне поєднання кіно та телебачення'
    },
    priceForServicesAmount: '{amount} послуги на суму',
    serviceCombinationDiscount: 'Знижка завдяки обʼєднанню послуг',
    total: 'Разом',
    perMonth: '/30 днів',
    // eslint-disable-next-line vue/max-len
    hint: 'Отримайте все, що вам потрібно для комфортного дозвілля, в одному зручному та доступному тарифі! <b>Високошвидкісний інтернет</b> для роботи й розваг, а також найкращі телеканали від <b>Sweet.tv</b> і <b>MEGOGO</b> для всієї родини – усе це за вигідною ціною.'
  },
  tv: {
    tvTitle: 'Телебачення',
    myDevices: 'Мої пристрої',
    yourTariff: 'Ваш тариф',
    allTariffs: 'Всі тарифи «DiaNet TV»',
    deleteDevice: 'Видалити пристрій',
    channelsListOther: 'Список телеканалів',
    tvTariffPrice: 'Вартість тарифу',
    trinityContract: 'Номер договору ТБ:',
    trinityPassword: 'Код активації TRINITY:',
    activationPassword: 'Пароль активації',
    passwordCopied: 'Пароль скопійовано',
    watchSweetTV: 'Дивитись у Sweet.tv',
    watchMegogo: 'Дивитись у Megogo',
    manageSweetTV: 'Керувати Sweet.tv',
    managementSweetTV: 'Керування ТБ Sweet.tv',
    manageMegogo: 'Керувати Megogo',
    managementMegogo: 'Керування Megogo',
    tvNotAvailable: 'На вашому тарифі ТБ недоступне',
    tvNotConnected: 'Телебачення не підключено',
    notConnected: 'Не підключено',
    notConnectedSubscription: 'Підписка не підключена',
    chooseSubscriptionForAccess: 'Оберіть підписку, щоб отримати доступ',
    chooseSubscriptionDescription: 'Оберіть одну з підписок та відкрийте доступ до понад 300 телеканалів, тисяч фільмів,' +
      ' серіалів та шоу стануть доступними одразу після активації!',
    choseSubscription: 'Обрати підписку',
    removeSubscription: 'Скасувати підписку',
    removeSubscriptionConformation: 'Ви впевнені, що бажаєте скасувати підписку «{tariff}»?',
    connectTv: 'Підключити TV',
    tvWillConnected: 'Телебачення буде підключено',
    needAddOneDevice: 'Для активації телебачення потрібно додати хоча б один пристрій',
    yourTvTariff: 'Ваш тариф ТБ',
    numberOfChannels: 'Кількість TV каналів',
    wantToDisable: 'Ви дійсно хочете вимкнути',
    disabledSuccessfully: 'успішно вимкнено',
    youHaveTariff: 'У вас тепер буде тариф',
    subscriptionConsist: 'абонплата складає',
    forEveryDevices: 'за кожні додані 4 пристрої.',
    connectedSuccessfully: 'успішно підключений',
    addNewDevice: 'Додати новий пристрій',
    addingDeviceByCode: 'Додати пристрій за кодом',
    addingDeviceByMac: 'Додати пристрій MAC',
    addPlaylist: 'Згенерувати плейлист',
    noDevices: 'У Вас поки не має підключених пристроїв',
    getDevices: 'Почекайте йде завантаження пристроїв',
    deviceName: `Ім'я пристрою`,
    deviceNamePlaceholder: 'Мій TV, Мій телефон, Мій планшет, Моя TV-приставка',
    deviceType: 'Тип пристрою',
    tv: 'Tелевізор',
    tvBox: 'TБ-приставка',
    phone: 'Телефон',
    tablet: 'Планшет',
    computer: `Комп'ютер`,
    deviceCode: 'Код з пристрою (4 цифри)',
    mac: 'MAC',
    addingDeviceFee: 'При додаванні пристрою абонплата збільшитися на',
    connectedDeviceFee: 'Стягується додаткова плата згідно з підключеним тарифом за кожні 4 пристрої',
    device: 'Пристрій',
    willDeleted: 'буде видалено',
    added: 'додано',
    tvSetting: 'Налаштування TБ',
    tvSettingTitle: 'Налаштування телебачення',
    pleaseNote: `Зверніть увагу: для налаштування ТБ, Вам необхідно бути підключеними до Інтернету від «DiaNet»!`,
    appInstall: '1. Встановлення додатку',
    phoneOrTablet: 'Телефон або планшет',
    phoneOrTabletHints: [`У App Store (для iOS) або Play Market (для Android) введіть у пошуку  
    «DiaNet TV» (або «Trinity TV») та встановіть додаток.`,
      `Запустивши дадаток, на екрані має відображатись 4 цифри - це код для авторизації Вашого пристрою 
    (телефону або планшета) в Особистому кабінеті.`,
      'Якщо Вас просять ввести номер телефону, перевірте, чи приєднані Ви зараз до мережі «DiaNet». '],
    phoneOrTabletAnnotation: `Якщо все підключено правильно, але додаток все одно вимагає ввести номер 
    телефону - будь-ласка, зверніться до технічної підтримки для виправлення помилки.`,
    tvOrTvBox: 'Телевізор або TБ-приставка',
    tvOrTvBoxHints: [`У магазині програм знайдіть «DiaNet TV» (або «Trinity TV») і встановіть додаток.`,
      `Відкривши програму, на екрані має відображатись 4 цифри - це код для авторизації Вашого телевізора або 
    ТБ-приставки в Особистому кабінеті.`,
      'Якщо Вас просять ввести номер телефону, перевірте, чи приєднані Ви зараз до мережі «DiaNet».'],
    notSupportedDevice: 'Програма «DiaNet TV» або «Trinity TV» не підтримується пристроєм: ',
    notSupportedDeviceHints: ['У магазині програм знайдіть і встановіть «Sweet.TV».',
      'Відкривши програму «Sweet.TV» після завантаження, введіть свій номер телефону.',
      'Ви отримаєте СМС-код з 4 цифр, необхідний для подальшої авторизації Вашого пристрою в Особистому кабінеті.'],
    notSupportedDeviceAnnotation: `УВАГА: термін дії коду від «Sweet.TV» - 1 хвилина, після закінчення цього часу 
    потрібно повторно ввести номер.`,
    addingScreenDevice: '2. Додавання пристрою в Особистому кабінеті:',
    screenDeviceHints: [`У Особистому кабінеті в меню «Телебачення» перейти до розділу «Мої пристрої» та натиснути 
    кнопку «Додати пристрій».`,
      `Ввести ім'я пристрою, тип пристрою та код, який Ви побачили, відкривши програму «DiaNet TV» (або «Trinity TV»).`,
      `Підтвердіть дію, натиснувши кнопку «Додати».`,
      `Після збереження налаштувань на пристрої відразу запрацює ТБ і в розділі «Мої пристрої» Ви побачите список 
    пристроїв, які було  додано.`],
    deviceNotSupport: `3. Якщо пристроєм не підтримується «DiaNet TV» («Trinity TV» або «Sweet.TV»): `,
    deviceNotSupportHints: [`В Особистому кабінеті, в меню «Телебачення» перейдіть до вкладки «Мої пристрої» та 
    натисніть кнопку - «Додати пристрій». `,
      `Натисніть на вкладку «Згенерувати плейлист» та оберіть ім'я та тип пристрою.`,
      `Підтвердіть дію, натиснувши кнопку «Додати».`,
      `Після цього у розділі “Мої пристрої” Ви побачите новий пристрій, в описанні якого буде посилання на плейлист. 
    Скопіюйте або завантажте його та вставте у програму для перегляду телебачення за плейлистом (наприклад VLC-player).`],
    screenDeviceAnnotation: `Зверніть увагу: у зв'язку з Політикою правовласника, у плейлисті відсутні 
    преміальні канали HD.`,
    deviceRemoved: 'Йде видалення пристрою',
    failedRemoveDevice: 'Не вдалося видалити пристрій!',
    tryAgain: 'Спробуйте ще раз пізніше.',
    failedAddDevice: 'Не вдалося додати пристрій!',
    correctCode: 'Переконайтеся, що ви ввели правильний код із пристрою або спробуйте пізніше.',
    waitConnecting: 'Зачекайте іде підключення...',
    channel: 'немає каналів | канал | канали | каналів',
    showChannelsList: 'Переглянути список',
    channelsList: 'Телеканали у тарифі',
    connected: 'Підключено',
    playlist: 'Плейлист',
    playlistCopy: 'Плейлист зкопійовано',
    noTV: 'В Вашем тарифному плане нет телевидения',
    modal: {
      title: 'Бажаєте змінити тариф?',
      titleDelete: 'Бажаєте видалити пристрій?',
      confirmTariffChange: 'Ви підтверджуєте зміну тарифу ТБ?',
      increase: 'збільшиться',
      decrease: 'зменшиться',
      textNewGroup: `Нажаль, Ви вже використовуєте 4 пристрої, які можна додати безкоштовно. <br>
      При додаванні нового пристрою, Ваша щоденна абонплата збільшиться на
       <span class="tariff-orange">{price} грн</span>.`,
      text: `Ви змінюєте свій тариф на <span class="tariff-orange">«{name}»</span>.<br> 
      Абонплата становитиме <span class="tariff-orange">{price} грн/день</span>, 
      а кількість телеканалів <span class="tariff-grey">{upgrade}</span> 
      до <span class="tariff-orange">{channels}</span>.`,
      textDelete: `Ви підтверджуєте видалення пристрою <span class="tariff-orange">«{name}»</span>`,
      deletedSuccessfully: 'Пристрій <span class="color-orange">«{name}»</span> успішно видалено',
      changeTariffOnce: 'Вибачте, але зміна тарифу можлива лише раз на добу.',
      tariffAlreadyChanged: `Ви сьогодні вже змінювали тариф. 
        У разі потреби додаткової зміни тарифу ви можете звернутися до служби підтримки`,
      tariffSuccessChanged: 'Тариф успішно змінено!',
      channel: 'немає каналів | {n} каналу | {n} каналів | {n} каналів',
      channelsList: 'Список телеканалів тарифу',
      screenAddError: 'Ви ввели некорректний код або код строк дії якого вичерпано',
      macAddressAlreadyExist: 'Пристрій з такою мак-адресою вже існує у системі',
      tariffFailedChangeTitle: 'Не вдалось змінити тариф!',
      tariffFailedChangeText: 'Щось пішло не так. Не вдалось змінити тариф. Повторіть спробу або спробуйте пізніше.',
      ableToSwitchTariffInDays: 'Зміна тарифу MEGOGO буде доступна через {days} днів',
      notAbleToCancelSubscription: `Тариф MEGOGO не можна буде скасувати або змінити протягом
        <span class="tariff-grey">{days} днів</span>.`,
      lowBalanceNotification: `У вас <span class="tariff-grey">недостатньо коштів на рахунку</span> для підключення тарифу.
        Для активації необхідно поповнити рахунок на суму <span class="tariff-orange">від {amount} грн.</span>`,
      enrollBalance: 'Поповнити баланс'
    },
    personalCabinet: 'Особистий кабінет',
    otherSweetTariffs: 'Інші тарифи Sweet.tv',
    otherMegogoTariffs: 'Інші тарифи Megogo',
    changePassword: 'Змінити пароль облікового запису'
  },
  feedback: {
    rateQuality: 'Оцінка якості',
    tellFriends: 'Дякуємо за Ваш відгук! Розкажіть про нас іншим',
    google: 'Залишити відгук у Google',
    tellUs: 'Як би Ви оцінили свій досвід використання послуг інтернет-провайдера «DiaNet»?',
    writeMessage: 'Залиши відгук',
    success: 'Ваш відгук успішно надіслано',
    thancks: 'Дякуємо, що допомагаєте нам ставати краще'
  },
  messages: {
    time: 'Час',
    date: 'Дата',
    messagesTitle: 'Повідомлення',
    archive: 'Архів',
    remove: 'Видалити',
    searchByMessages: 'Пошук по повідомленнях',
    sendToArchive: 'Відправити в архив',
    returnFromArchive: 'Видалити з архива',
    noMessages: 'Повідомлень немає'
  },
  minutes: 'зараз | {n} хвилина тому | {n} хвилини тому | {n} хвилин тому',
  support: {
    supportTitle: 'Підтримка',
    email: 'Електронна адреса',
    hoursOfWorkTitle: 'Години роботи',
    hoursOfWork: 'щодня з <time datetime="{from}">{from}</time> - <time datetime="{to}">{to}</time>',
    hoursOfWorkSupport: '24/7 без вихідних',
    socials: 'Соціальні мережі',
    phones: 'Телефони',
    writeRightNow: 'Цілодобова підтримка',
    onlineChat: 'Онлайн чат',
    addressTitle: 'Адреса',
    addressOfficeLeftTitle: 'Офіс Лівий Берег:',
    addressOfficeRightTitle: 'Офіс Правий Берег:',
    addressOfficeLeft: 'вул. Чумаченка, 40 м.Запоріжжя',
    addressOfficeRight: 'вул. Ажурна, 12А, Запоріжжя',
    officeOpen: 'Офіс працює',
    coverageMap: 'Карта покриття',
    altInstagram: 'DiaNet група в Instagram',
    altFacebook: `Зв'язатися з нами через Facebook`,
    altViber: 'Надіслати повідомлення на Viber',
    altTelegram: 'Підтримка інтернет компанії DiaNet у Telegram',
    checkWorkingHours: 'графік роботи уточнюйте'
  },
  profile: {
    profileTitle: 'Профіль',
    filling: 'Заповнення',
    fillingTooltip: 'Заповніть весь профіль',
    email: 'Email',
    profilePhoto: 'Фото профілю',
    chooseImage: 'Будь ласка, оберіть зображення для Вашого профілю.',
    changedSuccess: 'Аватар успішно змінено!'
  },
// Vuetify components translations
  $vuetify: {
    badge: 'Значок',
    close: 'Закрити',
    dataIterator: {
      noResultsText: 'Відповідних записів не знайдено',
      loadingText: 'Завантаження елементів...'
    },
    dataTable: {
      itemsPerPageText: 'Рядки на сторінці:',
      ariaLabel: {
        sortDescending: 'Відсортовано за спаданням.',
        sortAscending: 'Відсортовано за зростанням.',
        sortNone: 'Не відсортовані.',
        activateNone: 'Активуйте, щоб прибрати сортування.',
        activateDescending: 'Активуйте, щоб сортувати за спаданням.',
        activateAscending: 'Активуйте, щоб сортувати за зростанням.'
      },
      sortBy: 'Сортувати за'
    }
  },
  // project: 'Проект',
  btn: {
    add: 'Додати',
    back: 'Назад',
    sign: 'Увійти',
    cancel: 'Скасувати',
    save: 'Зберегти',
    detail: 'Деталі',
    edit: 'Редагувати',
    saveAndNext: 'Зберегти та продовжити',
    clear: 'Очистити',
    find: 'Шукати'
  },
  notify: {
    noData: '!?!',
    success: 'Виконано успішно',
    warning: 'Зверніть увагу',
    error: 'Помилка',
    info: 'Повідомлення',
    validation: {
      success: 'Виконано успішно',
      warning: 'Зверніть увагу',
      error: 'Помилка виконання',
      info: 'Повідомлення',
      required: 'Обов\'язкове поле',
      invalidFormat: 'Значення не вдповдає допустимому  {value}',
      invalidLength: 'Довжина повинна бути {length}',
      serverError: 'Помилка серверу (500)',
      invalidNumeric: 'Тільки цифри',
      noData: 'Немає даних',
      phoneNumber: "Введіть корректний номер",
      email: "Некоректна адреса електронної пошти",
      numeric: "Поле має бути числом",
      minLength: "Не менше {min} символів",
      maxLength: "Не більше {max} символів",
      phoneExist: "Такий номер вже існує",
      minValueUAH: "Не менше {min} грн",
      sameAsPassword: 'Паролі не співпадають'
    }
  },
  tvStatuses: {
    0: 'Активно',
    1: 'Адмін. блокування',
    2: 'Не активовано',
    3: 'Заморожено',
    5: 'Низький баланс',
    9: 'Деактивовано'
  },
  yes: 'Так',
  no: 'Ні',
  other: 'інших',
  SRU: 'SRU',
  UA: 'UA',
  available: 'Наявне',
  notAvailable: 'Не наявне',
  priceValue: 'грн / 30 днів',
  priceFree: 'Безкоштовно',
  notSelected: 'Не обрано',
  moreInformation: 'Більше інформації',
  lessInformation: 'Менше інформації',
  invalidAction: 'Наразі ця дія неможлива. Зверніться до служби підтримки.',
  contactSupport: 'Звʼязатись з підтримкою'
}
