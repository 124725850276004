import {required, requiredIf, numeric, email, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'

const phoneNumber = value => {
	return value.replace(/[+\s]/g, '').length === 12
}

const phoneExist = (value, phones) => {
  const phonesLength = Object.values(phones).filter(item => item === value).length
  return phonesLength < 2
}

export const formLogin = {
  login: { required, numeric },
  password: { required }
}

export const formProfile = {
  phone: { $each: { phoneNumber, phoneExist }},
  email: { required, email }
}

export const addPhone = {
  phone: { phoneNumber },
  code: { required, minLength: minLength(6) }
}

export const formCode = activeType => ({
  name_device: { required },
  type_device: { required },
  mac_address: { required: requiredIf(() => activeType === 'mac'), minLength: minLength(17) },
  code: { required: requiredIf(() => activeType === 'code'), numeric }
})

export const tvTariffPasswordChange = {
  password: { required, minLength: minLength(6), maxLength: maxLength(16) },
  passwordConfirmation: { required, sameAsPassword: sameAs('password') }
}
