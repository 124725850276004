export default {
  bankingFee: 'Платежные системы могут взимать дополнительную комиссию.',
  metaTitle: 'Личный кабинет | DiaNet',
  disableBonusesForLegal: 'Недоступно для юр. лиц',
  error: {
    notfound: 'Страница не найдена',
    other: 'Виявлено помилку',
    serverError: 'Ошибка сервера! Повторите, пожалуйста снова',
    paymentError: 'Непредвиденная ошибка. Обратитесь в службу поддержки.'
  },
  header: {
    language: 'Язык',
    logout: 'Выйти',
    theme: 'Тема',
    message: 'Сообщения',
    messageNew: 'нет новых | {n} новое | {n} новых',
    messageMore: 'Больше...'
  },
  footer: {
    terms: 'Условия использования личного кабинета'
  },
  login: {
    invalidUserTypeAuth: 'Авторизация с этим типом аккаунта невозможна',
    authorization:'Авторизация',
    comeback: 'С возвращением! Пожалуйста, введите свои данные для входа.',
    accountAbsend: 'Еще нет учетной записи?',
    connect: 'Подключайтесь!',
    qrByPhoneEnter: 'QR-код для входу через смартфон',
    enterByNumber: 'Войти по номеру',
    enterByQrCode: 'Войти по QR-коду',
    requestCodeAgain: 'ЗАПРОСИТЬ КОД ПОВТОРНО',
    phoneNumber: 'Номер телефона',
    enterCode: 'Введите код',
    enterCodeFromSMS: 'Введите код подтвердження, который придет Вам в SMS-сообщении, и нажмите "Продолжить".',
    codeEnter: 'Продолжить',
    title: 'Войти',
    sendNewCode: 'Отправить код еще раз',
    enterAnotherNumber: 'Ввести другой номер',
    resendCode:'Нажмите для отправки кода',
    codeResend: 'Код отправлен повторно',
    codeSent: 'Код отправлен на номер: ',
    scanQr: 'Отсканируйте этот QR-код в приложении «Мой DiaNet» для авторизации на сайте.',
    login: 'Логин',
    password: 'Пароль',
    passwordConfirmation: 'Подтверждение пароля',
    codeScanned: 'Код отсканирован подтвердите вход на устройстве',
    codeExpired: 'Время действия кода исчерпано',
    authorized: 'Авторизовано',
    authorizationDenied: 'Авторизация отклонена. Попробуйте еще раз',
    chooseAccount: 'Выберите учетную запись для входа:',
    error: {
      enterPhoneNumber: 'Введите корректный номер телефона.',
      tooMutchRequests: 'Слишком много попыток. Попробуйте еще раз позже.',
      phoneLimit: 'Лимит попыток исчерпан. Обратитесь к оператору для уточнения кода авторизации.',
      oldCode: 'Код устарел (прошло более 30 минут), попробуйте снова',
      wrongCode: 'Некорректный код, попробуйте снова',
      wrongLoPass: 'Логин или пароль не верны',
      limitHour: 'Попытки входа исчерпаны. Попробуйте через 1 час или обратитесь в отдел поддержки',
      serverError: 'Ошибка сервера! Повторите, пожалуйста, авторизацию снова',
      authError: 'Ошибка авторизации, попробуйте снова',
      tryAgain: 'Произошла ошибка попробуйте позже'
    }
  },
  sidebar:{
    main: "Главная",
    payment: "Оплата",
    moneyOperation: "Денежные операции",
    paymentsHistory: "История оплат",
    feesHistory: "История списаний",
    tariffs: "Тарифы",
    tv: "Телевидение",
    sweetTv: "Просмотр SWEET TV",
    trinityTv: "Просмотр TRINITY TV",
    support: "Поддержка",
    message: "Сообщения",
    messageArchive: "Архив сообщений",
    site: "Перейти на сайт",
    exit: "Выход",
    bonuses: "Бонусы",
    profile: "Профиль",
    mobileApp: "Моб. приложение",
    scanQR: 'Отсканируйте QR-код'
  },
  modal: {
    attention: 'Обратите внимание!',
    enterLogPas: 'Введите логин и пароль',
    login: 'Логин',
    password: 'Пароль',
    addAccount: 'Добавить аккаунт',
    addressHide: 'Адрес скрыт',
    invalidLoginPassword: 'Неверный логин и пароль, попробуйте еще раз',
    enterLoginPassword: 'Введите логин и пароль',
    error: 'Возникла ошибка. Попробуйте позже или свяжитесь с технической поддержкой.',
    tryAgain: 'Попробуйте снова',
    edit: 'Редактировать персональные данные',
    addNumber: 'Добавить еще один номер телефона',
    numberError: 'Пожалуйста дозаполните номер телефона либо удалите его.',
    emailError: 'Пожалуйста введите корректный email.',
    save: 'Сохранить',
    detailInfo: 'Детальная информация',
    fio: 'Ф.И.О:',
    number: 'Номер:',
    deleteIP: 'Отключить «Белый IP-адрес»?',
    addIP: 'Подключить «Белый IP-адрес»?',
    ipDescription: 'Услуга «Белый IP-адрес» позволяет получить свой персональный статический IP в сети.',
    btnYes: 'Да',
    btnNo: 'Нет',
    ipChanged: 'IP адрес изменен',
    updated: 'Изменения внесены',
    freeze: 'Хотите заморозить свой счёт?',
    unfreeze: 'Хотите разморозить свой счёт?',
    freezeQueue: 'Заморозка счёта в очереди',
    attention: 'Обратите внимание!',
    freezeDescription: `Благодаря услуге «Заморозка», 
      Вы можете не платить полную стоимость тарифа в случаях, когда не планируете пользоваться доступом к сети Интернет.`,
    earlierMinimalPeriodDescription: `При отключении услуги «Заморозка счёта» раньше минимального срока, 
      с Вас будет списана абонплата за 2 дня использования текущего тарифа.`,
    freezeDone: 'Учетная запись заморожена',
    unfreezeDone: 'Учетная запись разморожена',
    scheduledFreezeCanceled: 'Запланированная заморозка отменена',
    servicePrice: 'Стоимость услуги: <span>{price} грн/день</span>',
    minServiceUsing: 'Минимальный срок пользования услугой: <span>{days} дня</span>',
    ipWillChanged: `Вы уверены, что хотите выключить услугу "Белый IP-адрес"? 
      Обратите внимание: при повторной активации этой услуги ваш IP-адрес будет изменен.`,
    withdrawalAmount: 'Сумма списания составит: <span>{price} грн</span>',
    dayWithdrawalAmount: 'Будет списана дневная абонплата в размере: <span>{price} грн</span>',
    sureTurnOffServiceEarlier: 'Вы уверены, что хотите отключить услугу раньше времени?',
    turnOffServiceEarlier: 'Вы деактивируете услугу «Заморозка счёта» ранее установленного периода.',
    internetUnfreeze: `Доступ в Интернет будет возобновлен, 
      а все списания средств будут осуществляться согласно текущему тарифу.`,
    activatedAccountFreeze: 'Вы уже активировали услугу "Заморозка счёта". Она начнет действовать с выбранной Вами даты.',
    cancelActivateFreeze: 'У Вас есть возможность отменить активацию услуги или изменить уже выбранные даты на другие.',
    setCredit: 'Активировать «Экспресс-кредит»?',
    setCreditOnTime: 'Вам будет предоставлена ​​возможность использования Интернет-услуг в кредит на <span>3 дня</span>.',
    setCreditDate: 'Кредит будет действовать до <span class="date">{date}</span>.',
    creditOk: 'Кредит установлен',
    creditError: 'Ошибка, обратитесь в службу технической поддержки',
    freezeErrors: {
      date_start: 'Дата начала заморозки',
      date_end: 'Дата конца заморозки',
      required: 'поле обязательно',
      startTomorrow: 'должна начаться с завтрашнего дня',
      laterDateStart: 'должна начинаться как минимум на 3 дня позже даты начала',
      lackClientBalance: 'Недостаточно средств на счету',
      commonError: 'Ошибка, обратитесь в службу технической поддержки'
    },
    app: {
      title: 'Загрузите приложение “Мой DiaNet”',
      outOfMoneyMessage: 'удобное получение уведомлений об окончании денег на балансе',
      appAuthorization: 'авторизация через мобильное приложение',
      payment: 'удобная оплата',
      serviceManagement: 'управление услугами всегда под рукой'
    },
    notEnoughMoney: `Изменение тарифа при отрицательном балансе или во время действия услуги "Заморозка" запрещено.`,
    notEnoughMoneyIP: `Подключение дополнительных услуг при отрицательном балансе или во время действия 
        услуги "Заморозка" запрещено.`,
    notEnoughMoneyDevice: 'Добавление устройств при отрицательном балансе или во время действия Заморозки запрещено.',
    freezeForbidden: `Для активации услуги <span>"Заморозка"</span> 
      необходимо сначала отключить активную услугу <span>"Белый IP-адрес"</span>.`
  },
  mBit: 'Мбит/с',
  gBit: 'Гбит/с',
  add: 'Добавить',
  toCancel: 'Отменить',
  cancel: 'Отмена',
  apply: 'Применить',
  confirm: 'Подтвердить',
  close: 'Закрыть',
  areYouSure: 'Вы уверены?',
  required: 'Обязательное поле',
  details: 'Подробнее',
  search: 'Поиск',
  change: 'Изменить',
  delete: 'Видалити',
  connect: 'Подключить',
  turnOff: 'Отключить',
  conditions: 'Условия',
  reject: 'Отклонить',
  continue: 'Продолжить',
  mainPage:{
    login: 'Логин',
    detailInfo: 'Детальная информация',
    address: 'Адрес:',
    balans: 'Баланс',
    tariff: 'Тариф',
    tv: "Телевидение",
    ip: 'IP адрес',
    pay: 'Пополнить',
    sale: 'Скидка',
    yearAction: 'Акция «12 + 1 месяц»',
    getAction: 'Подключить',
    connectedAction: 'Подключено',
    changeTariff: 'Изменить тариф',
    changeTariffForbidden: 'Низкий баланс для смены тарифа',
    tariffTV: 'Тариф TВ',
    tvDescription: 'Подробнее о ТВ',
    freeze: 'Заморожено',
    small_deposit: 'Маленький депозит',
    freeze_: 'Заморозить учетную запись',
    unfreeze: 'Разморозить учетную запись',
    credit: 'Кредит',
    setCredit: 'Экспресс кредит',
    setCreditOnTime: 'Подключить кредит на 3 дня',
    cancelCredit: 'Отключить кредит',
    creditPeriod: 'Кредит активен до',
    creditActive: 'Активен',
    payAccount: 'Пополнить'
  },
  slider: {
    buttonConnect: 'Связаться с нами',
    list: {
      '13months': {
        period: 'Действует на постоянной основе',
        title: '13 месяцев в году',
        subtitle: 'Оплачивайте абонплату сразу за 12 месяцев и получите +1 месяц в подарок!',
        tag: 'Акция «13 месяцев в году»',
        buttonName: 'Подробнее'
      },
      bonusSystem: {
        period: 'Действует на постоянной основе',
        title: 'Получайте Бонусы',
        subtitle: 'Пользуйтесь услугами «DiaNet», получайте бонусы на свой счёт и экономьте деньги вместе с нами!',
        tag: 'Присоединяйтесь к Программе Лояльности',
        buttonName: 'Подробнее'
      }
    }
  },
  dashBoards: {
    your_tariff: 'Ваш тариф',
    internet: 'Интернет',
    tv: 'Телевидение',
    sweetTv: 'Тариф ТВ Sweet.tv',
    megogo: 'Подписка ТВ MEGOGO',
    pay: 'Стоимость тарифа',
    pay_to: 'Оплачено до {date}',
    credit_to: 'Кредит до {date}',
    account_state: 'Статус',
    active: 'Активный',
    notActive: 'Неактивированый',
    freeze: 'Замороженый',
    small_deposit: 'Маленький депозит',
    stop: 'Отключен',
    account: 'Логин',
    ip: 'IP-адрес',
    address: 'Адрес',
    bonuses: 'Бонусы',
    bonus_on_account: 'На счету',
    standing: 'Стаж',
    bonus_count: 'нет бонусов | бонус | бонуса | бонусов',
    year: '0 лет | год | года | лет',
    monthShort: 'мес.',
    month: '0 месяцев | месяц | месяца | месяцев',
    dataPayment: 'Данные для оплаты',
    toPay: 'Оплатить',
    chooseAmount: 'Выберите сумму',
    enterYourValue: 'Внести своё значение',
    currentBalance: 'Текущая сумма на счëте:',
    balanceAfterPayment: 'Сумма после пополнения:',
    expireDateAfterPayment: 'Средств хватит до:',
    paymentMethod: 'Платежная система',
    personalData: 'Личные данные',
    fio: 'Ф.И.О',
    phoneNumber: 'Номер телефона',
    email: 'Электронный адрес',
    connectionInfo: 'Данные подключения',
    connectionDate: 'Дата подключения',
    validity: 'Срок действия',
    tariff: 'Тариф',
    connectionAddress: 'Адрес подключения',
    region: 'Населëнный пункт',
    street: 'Улица',
    houseNumber: 'Номер дома',
    flat: 'Квартира',
    socials: 'Социальные сети',
    connect: 'Подключить',
    addSocial: 'Добавьте соцсеть',
    socialLink: 'Добавте ссылки на свои социальные сети.',
    numberAlreadyAdded: 'Номер уже добавлен',
    codeSend: 'Код отправлен на номер:',
    addPhone: 'Добавить номер',
    enterPhone: 'Пожалуйста, укажите номер телефона.',
    numberConfirmation: 'Подтверждение номера',
    enterCode: 'Введите код подтверждения, который был отправлен Вам в SMS-сообщении и нажмите «Подтвердить».',
    smsCode: 'SMS-код',
    sendCodeAgain: 'Отправить код еще раз',
    enterAnotherPhone: 'Ввести другой номер',
    successProfile: 'Ваши данные изменены успешно',
    success: 'Успех',
    error: 'Ошибка',
    priceList: 'Прайс лист услуг',
    compareTVTariffs: 'Сравнить тарифы ТВ',
    shopDianet: 'Магазин DiaNet'
  },
  bonus_count: 'нет бонусов | {n} бонус | {n} бонуса | {n} бонусов',
  yearAction:{
    title: '🎁 Акция «13 месяцев в году»',
    text: 'Оплатите свой тариф сразу за 12 месяцев и получите скидку 8,33% на пользование услугами на 360 дней',
    readAbout: 'Ознакомиться с условиями акции',
    connectionAction: 'Акция подключена',
    btnYes: 'Подключить',
    btnNo: 'Отменить'
  },
  payment: {
    paymentTitle: 'Оплата',
    additional: {
      additionalTittle: 'Дополнительно',
      freeze: 'Заморозить счëт',
      unfreeze: 'Разморозить счëт',
      smallDeposit: 'Маленький депозит',
      waitFreeze: 'Ожидает заморозку',
      addWhiteIp: 'Подключить «Белый IP-адрес»',
      deleteWhiteIp: 'Отключить «Белый IP-адрес»',
      helpUmf: 'Помощь ВСУ'
    },
    historyPayments: 'История оплат',
    historyFees: 'История списаний',
    table: {
      date: 'Дата',
      year: 'Год',
      description: 'Описание',
      sum: 'Сумма',
      paymentSystem: 'Платёжная система',
      transaction: 'Транзакция',
      status: 'Статус',
      deposit: 'Счëт после пополнення',
      statusType: {
        processing: 'Обработка',
        end: 'Завершена',
        cancel: 'Отменена',
        error: 'Ошибка оплаты'
      }
    }
  },
  bonusesQuantity: 'Без бонусов | {n} бонус | {n} бонуса | {n} бонусов',
  bonuses: {
    bonusesTitle: 'Бонусы',
    allAchievements: 'Все награды',
    yourAchievements: 'Ваши награды',
    noAchievements: 'Доступные',
    activateBonuses: 'Активировать бонусы',
    activateBonusesDescription: 'Присоединяйтесь к Программе лояльности от «DiaNet» и получайте регулярные бонусы на свой счёт! Ознакомьтесь с детальными <a href="https://dianet.online/contracts#bonus" target="_blank">условиями</a> и начните экономить на услугах уже сегодня.',
    activateBonusesAgree: 'Я ознакомлен(-а) с <a href="https://dianet.online/contracts#bonus" target="_blank">условиями</a> использования Программы лояльности и согласен с ними.',
    withdrawMainAccount: 'Вывести на счëт',
    withdrawBonuses: 'Хотите вывести бонусы?',
    withdrawBonusesDescription: `Вы выводите <span>{count} бонусов</span> на свой основной счёт. 
      Баланс будет пополнен на <span>{count} грн</span>.`,
    referralProgram: 'Реферальная программа',
    referralInvite: 'Пригласите друга присоединиться к сети «DiaNet» и оба получите по 100 бонусов на счёт.',
    referralInviteLink: 'Ссылка-приглашение',
    referralCopied: 'Приглашение скопирвоано',
    conditions: 'Условия использования',
    notHaveAchievements: 'У Вас пока нет наград!'
  },
  channel: 'нет каналов | {n} канал | {n} канала | {n} каналов',
  declinedChannel: 'нет каналов | канал | канала | каналов',
  tariffs: {
    tariffsTitle: 'Тарифы',
    yourTariff: 'Ваш тариф',
    whiteIpActive: '«Белый IP-адрес» подключён',
    whiteIpInactive: '«Белый IP-адрес» не подключён',
    discountActive: 'Скидка на тариф: -{discount}%',
    discountInactive: 'Скидок нет',
    freezingActive: 'Действует услуга «Заморозка счета»',
    freezingInactive: '«Заморозка счета» не активна',
    paidTvActive: 'Подключен платный тариф ТВ',
    paidTvInactive: 'Бесплатный тариф ТВ',
    paidDevicesActive: 'Подключены платные устройства',
    paidDevicesInactive: 'Нет платных устройств',
    tariffCost: `<span class="term__title">Стоимость тарифа</span>
        <span class="term__quantity">{cost}<span> грн / 30 дней</span></span>`,
    internetSpeed: '<span class="term__title">Интернет</span><span class="term__quantity">{speed}</span>',
    sweetTvChannels: `<span class="term__title">Тариф ТВ Sweet.tv</span>
        <span class="term__quantity">{channels}<span> каналов</span></span>`,
    megogoTvChannels: `<span class="term__title">Подписка ТВ MEGOGO</span>
        <span class="term__quantity">{channels}<span> каналов</span></span>`,
    tv: 'Количество телеканалов',
    otherTariffs: 'Другие тарифы «DiaNet»',
    modal: {
      title: 'Хотите изменить тариф?',
      confirmTariffChange: 'Вы подтверждаете смену тарифа ТВ?',
      increase: 'увеличится',
      decrease: 'снизится',
      same: 'останется',
      text: `Вы меняете свой тариф на <span class="tariff-orange">«{name}»</span>.<br> 
        Абонплата будет составлять <span class="tariff-orange">{price} грн/день</span>, 
        а скорость Интернета <span class="tariff-grey">{upgrade}</span> 
        до <span class="tariff-orange">{speed}</span>.<br>
        Для просмотра TV будет доступно: <span class="tariff-grey">{channels}</span>.`,
      changeTariffOnce: 'Извините, но смена тарифа возможна только раз в сутки.',
      tariffAlreadyChanged: `Возникли технические проблемы, попробуйте пожалуйста позже или 
        обратитесь в службу поддержки`,
      tariffSuccessChanged: 'Тариф успешно изменен!',
      tariffFailedChangeTitle: 'Не удалось изменить тариф!',
      tariffFailedChangeText: 'Что-то пошло не так. Не удалось изменить тариф. Повторите попытку или попробуйте позже.',
      ableToSwitchTariffInDays: 'Изменение тарифа MEGOGO будет доступно через {days} дней',
      notAbleToCancelSubscription: `Тариф MEGOGO нельзя будет отменить или изменить в течение 
        <span class="tariff-grey">{days} дней.</span>`,
      lowBalanceNotification: `У вас <span class="tariff-grey">недостаточно средств на счету</span> для подключения тарифа.
        Для активации необходимо пополнить счет на сумму <span class="tariff-orange">от {amount} грн.</span>`,
      enrollBalance: 'Пополнить баланс'
    },
    plans: {
      sweetTv: 'Sweet.tv',
      megogo: 'MEGOGO',
      bothVariants: 'Оба ТВ'
    },
    channelsList: 'Список каналов'
  },
  tariffsConstructor: {
    title: 'Конструктор тарифа',
    internet: {
      title: 'Интернет',
      subtitle: 'Выберите оптимальную скорость интернета для ваших потребностей'
    },
    sweetTv: {
      title: 'Пакет ТВ Sweet.tv',
      subtitle: 'Раскройте возможности с пакетом, созданным для Вас'
    },
    megogo: {
      title: 'Подписка на ТВ MEGOGO',
      subtitle: 'Находите идеальное сочетание кино и телевидения'
    },
    priceForServicesAmount: '{amount} услуги на сумму',
    serviceCombinationDiscount: 'Скидка благодаря объединению услуг',
    total: 'Итого',
    perMonth: '/30 дней',
    // eslint-disable-next-line vue/max-len
    hint: 'Получите все, что вам нужно для комфортного досуга, в одном удобном и доступном тарифе! <b>Высокоскоростной интернет</b> для работы и развлечений, а также лучшие телеканалы от <b>Sweet.tv</b> и <b>MEGOGO</b> для всей семьи - все это по выгодной цене.'
  },
  tv: {
    tvTitle: 'Телевидение',
    myDevices: 'Мои устройства',
    yourTariff: 'Ваш тариф',
    allTariffs: 'Все тарифы «DiaNet TV»',
    deleteDevice: 'Удалить устройство',
    channelsListOther: 'Список телеканалов',
    tvTariffPrice: 'Стоимость тарифа',
    trinityContract: 'Номер договора ТВ:',
    trinityPassword: 'Код активации TRINITY:',
    passwordCopied: 'Код скопирован',
    activationPassword: 'Пароль активации',
    watchSweetTV: 'Смотреть в Sweet.tv',
    watchMegogo: 'Смотреть в Megogo',
    manageSweetTV: 'Управлять Sweet.tv',
    managementSweetTV: 'Управление ТВ Sweet.tv',
    manageMegogo: 'Управлять Megogo',
    managementMegogo: 'Управление Megogo',
    tvNotAvailable: 'На вашем тарифе ТВ не доступно',
    tvNotConnected: 'Телевидение не подключено',
    notConnected: 'Не подключено',
    notConnectedSubscription: 'Подписка не подключена',
    chooseSubscriptionForAccess: 'Выберите подписку, чтобы получить доступ',
    chooseSubscriptionDescription: 'Выберите одну из подписок и откройте доступ к более 300 телеканалов, тысяч фильмов,' +
      ' сериалов и шоу станут доступными сразу после активации!',
    choseSubscription: 'Выбрать подписку',
    removeSubscription: 'Отменить подписку',
    removeSubscriptionConformation: 'Вы уверены, что хотите отменить подписку «{tariff}»?',
    connectTv: 'Подключить TV',
    tvWillConnected: 'Телевидение будет подключено',
    needAddOneDevice: 'Для активации телевидения нужно добавить хотя бы одно устройство',
    yourTvTariff: 'Ваш тариф ТВ',
    numberOfChannels: 'Количество TV Каналов',
    wantToDisable: 'Вы действительно хотите отключить',
    disabledSuccessfully: 'успешно отключен',
    youHaveTariff: 'У вас теперь будет тариф',
    subscriptionConsist: 'абонплата составляет',
    forEveryDevices: 'за каждые добавленные 4 устройства.',
    connectedSuccessfully: 'успешно подключен',
    addNewDevice: 'Добавить новое устройство',
    addingDeviceByCode: 'Добавить устройство по коду',
    addingDeviceByMac: 'Добавить устройство по MAC',
    addPlaylist: 'Сгенерировать плейлист',
    noDevices: 'У Вас пока нет добавленних устройств',
    getDevices: 'Подождите идет загрузка устройств',
    deviceName: 'Имя устройства',
    deviceNamePlaceholder: 'Мой TV, Мой телефон, Мой планшет, Моя TV-приставка',
    deviceType: 'Тип устройства',
    tv: 'Tелевизор',
    tvBox: 'TB-приставка',
    phone: 'Телефон',
    tablet: 'Планшет',
    computer: 'Компьютер',
    deviceCode: 'Код с устройства (4 цифры)',
    mac: 'MAC',
    addingDeviceFee: 'При добавлении устройства  абонплата увеличиться на',
    connectedDeviceFee: 'Взимается дополнительная плата согласно подключенного тарифа за каждые 4 устройства',
    device: 'Устройство',
    willDeleted: 'будет удалено',
    added: 'добавлено',
    tvSetting: 'Настройка TВ',
    tvSettingTitle: 'Настройка телевидения',
    pleaseNote: `Обратите внимание: для настройки ТВ, Вам необходимо подключиться к Интернету от «DiaNet»!`,
    appInstall: '1. Установка приложения',
    phoneOrTablet: 'Телефон или планшет',
    phoneOrTabletHints: [`В App Store (для iOS) или Play Market (для Android) введите поиск «DiaNet TV» 
      (или «Trinity TV») и установите приложение.`,
      `Запустив приложение, на экране должно отобразится 4 цифры - это код для авторизации устройства 
      (телефона или планшета) в Личном кабинете.`,
      'Если Вас просят ввести номер телефона, проверьте, подключены ли Вы сейчас к сети «DiaNet».'],
    phoneOrTabletAnnotation: `Если все подключено правильно, но приложение все равно требует 
      ввести номер телефона – пожалуйста, обратитесь к технической поддержке для устранения ошибки.`,
    tvOrTvBox: 'Телевизор или TВ-приставка',
    tvOrTvBoxHints: [`В магазине программ найдите «DiaNet TV» (или «Trinity TV») и установите приложение.`,
      `Открыв программу, на экране должно отображаться 4 цифры - это код для авторизации телевизора или 
      ТВ-приставки в личном кабинете.`,
      'Если Вас просят ввести номер телефона, проверьте, подключены ли Вы сейчас к сети «DiaNet».'],
    notSupportedDevice: 'Программа «DiaNet TV» или «Trinity TV» не поддерживается устройством:',
    notSupportedDeviceHints: ['В магазине программ найдите и установите «Sweet.TV».',
      'Откройте приложения «Sweet.TV» и введите свой номер телефона.',
      'Вы получите СМС-код из 4-х цифр, необходимый для дальнейшей авторизации устройства в Личном кабинете.'],
    notSupportedDeviceAnnotation: `ВНИМАНИЕ: срок действия кода от «Sweet.TV» – 1 минута, по истечении этого 
      времени нужно повторно ввести номер.`,
    addingScreenDevice: '2. Добавление устройства в Личный кабинет:',
    screenDeviceHints: [`В Личном кабинете в меню «Телевидение» перейдите в раздел «Мои устройства» и нажмите 
      кнопку «Добавить устройство».`,
      `Введите имя устройства, тип устройства и код, который Вы увидели, открыв программу «DiaNet TV» (или «Trinity TV»).`,
      `Подтвердите действие, нажав кнопку «Добавить».`,
      `После сохранения настроек на устройстве сразу заработает ТВ и в разделе «Мои устройства» Вы увидите список 
      добавленных устройств.`],
    deviceNotSupport: `3. Если устройством не поддерживается «DiaNet TV» («Trinity TV» или «Sweet.TV»): `,
    deviceNotSupportHints: [`В Личном кабинете, в меню «Телевидение» перейдите на раздел «Мои устройства» и нажмите 
      кнопку - «Добавить устройство».`,
      `Нажмите на вкладку «Сгенерировать плейлист» и выберите имя или тип устройства.`,
      `Подтвердите действие, нажав кнопку «Добавить».`,
      `После этого в разделе "Мои устройства" Вы увидите новое устройство, в описании которого будет ссылка на плейлист. 
      Скопируйте или загрузите его и вставьте в программу просмотра телевидения по плейлисту (например, VLC-player).`],
    screenDeviceAnnotation: `Обратите внимание: в связи с Политикой правообладателя в плейлисте отсутствуют 
      премиальные каналы HD.`,
    deviceRemoved: 'Идет удаление устройства',
    deletedSuccessfully: 'успешно удалено',
    failedRemoveDevice: 'Не удалось удалить устройство!',
    tryAgain: 'Попробуйте еще раз позже.',
    failedAddDevice: 'Не удалось добавить устройство!',
    correctCode: 'Убедитесь что вы ввели правильный код с устройства или попробуйте позже.',
    waitConnecting: 'Подождите идет подключение...',
    channel: 'нет каналов | канал | канала | каналов',
    showChannelsList: 'Посмотреть список',
    channelsList: 'Телеканалы в тарифе',
    connected: 'Подключено',
    playlist: 'Плейлист',
    playlistCopy: 'Плейлист скопирован',
    noTV: 'У Вашому тарифному плані не має телебачення',
    modal: {
      title: 'Хотите изменить тариф?',
      titleDelete: 'Хотите удалить устройство?',
      confirmTariffChange: 'Вы подтверждаете смену тарифа ТВ?',
      increase: 'увеличится',
      decrease: 'снизится',
      textNewGroup: `К сожалению, Вы уже используете 4 устройства, которые можно добавить 
        бесплатно.<br> 
        При добавлении нового устройства Ваша ежедневная абонплата увеличится на 
        <span class="tariff-orange">{price} грн/день</span>`,
      text: `Вы меняете свой тариф на <span class="tariff-orange">«{name}»</span>.<br> 
        Абонплата будет составлять <span class="tariff-orange">{price} грн/день</span>, 
        а количество телеканалов <span class="tariff-grey">{upgrade}</span> 
        до <span class="tariff-orange">{channels}</span>.`,
      textDelete: `Вы подтверждаете удаление устройства <span class="tariff-orange">«{name}»</span>`,
      deletedSuccessfully: 'Устройство <span class="color-orange">«{name}»</span> успешно удалено',
      changeTariffOnce: 'Извините, но смена тарифа возможна только раз в сутки.',
      tariffAlreadyChanged: `Вы сегодня уже меняли тариф. 
        В случае необходимости дополнительной смены тарифа вы можете обратиться в службу поддержки`,
      tariffSuccessChanged: 'Тариф успешно изменен!',
      channel: 'нет каналов | {n} канала | {n} каналов | {n} каналов',
      channelsList: 'Список телеканалов тарифа',
      screenAddError: 'Вы ввели некорректный код или код срок действия которого исчерпан',
      macAddressAlreadyExist: 'Пристрій з такою мак-адресою вже існує у системі',
      tariffFailedChangeTitle: 'Не удалось изменить тариф!',
      tariffFailedChangeText: 'Что-то пошло не так. Не удалось изменить тариф. Повторите попытку или попробуйте позже.'
    },
    personalCabinet: 'Личный кабинет',
    otherSweetTariffs: 'Другие тарифы Sweet.tv',
    otherMegogoTariffs: 'Другие тарифы Megogo',
    changePassword: 'Изменить пароль учетной записи'
  },
  feedback: {
    rateQuality: 'Оценка качества',
    tellFriends: 'Спасибо за Ваш отзыв! Расскажите о нас другим',
    google: 'Оставить отзыв в Google',
    tellUs: 'Как бы Вы оценили свой опыт использования услуг интернет-провайдера «DiaNet»?',
    writeMessage: 'Оставить отзыв',
    success: 'Ваш отзыв успешно отправлен',
    thancks: 'Спасибо, что помогаете нам становиться лучше'
  },
  messages: {
    time: 'Время',
    date: 'Дата',
    messagesTitle: 'Сообщения',
    archive: 'Архив',
    remove: 'Удалить',
    searchByMessages: 'Поиск по сообщениям',
    sendToArchive: 'Отправить в архив',
    returnFromArchive: 'Удалить из архива',
    noMessages: 'Сообщений нет'
  },
  minutes: 'сейчас | {n} минута назад | {n} минуты назад | {n} минут назад',
  support: {
    supportTitle: 'Поддержка',
    email: 'Электронный адрес',
    hoursOfWorkTitle: 'Часы работы',
    hoursOfWork: 'ежедневно с <time datetime="{from}">{from}</time> - <time datetime="{to}">{to}</time>',
    hoursOfWorkSupport: '24/7 без выходных',
    socials: 'Социальные сети',
    phones: 'Телефоны',
    writeRightNow: 'Круглосуточная поддержка',
    onlineChat: 'Онлайн чат',
    addressTitle: 'Адрес',
    addressOfficeLeftTitle: 'Офис Левый Берег:',
    addressOfficeRightTitle: 'Офис Правый Берег:',
    addressOfficeLeft: 'ул. Чумаченко, 40 г.Запорожье',
    addressOfficeRight: 'ул. Ажурная, 12А, г. Запорожье',
    officeOpen: 'Офис работает',
    coverageMap: 'Карта покрытия',
    altInstagram: 'DiaNet группа в Instagram',
    altFacebook: 'Связаться с нами через Facebook',
    altViber: 'Отправить сообщение с помощью Viber',
    altTelegram: 'Поддержка интернет компании DiaNet в Telegram',
    checkWorkingHours: 'график работы уточняйте'
  },
  profile: {
    profileTitle: 'Профиль',
    filling: 'Заполнение',
    fillingTooltip: 'Заполните весь профиль',
    email: 'Email',
    profilePhoto: 'Фото профиля',
    chooseImage: 'Пожалуйста, выберите изображение для вашего профиля.',
    changedSuccess: 'Аватар успешно изменен!'
  },
  // Vuetify components translations
  $vuetify: {
    badge: 'Значок',
    close: 'Закрыть',
    dataIterator: {
      noResultsText: 'Соответсвующих записей не найдено',
      loadingText: 'Загрузка элементов...'
    },
    dataTable: {
      itemsPerPageText: 'Элементов на странице:',
      ariaLabel: {
        sortDescending: 'Сортирвка по убыванию.',
        sortAscending: 'Сортирвка по возрастанию.',
        sortNone: 'Не отсортированы.',
        activateNone: 'Активируйте, что бы убрать сортировку.',
        activateDescending: 'Активируйте, что бы сортувать по убыванию.',
        activateAscending: 'Активируйте, что бы сортувать по возрастанию.'
      },
      sortBy: 'Сортувать по'
    }
  },
  // project: 'Проект',
  btn: {
    add: 'Добавить',
    back: 'Назад',
    sign: 'Войти',
    cancel: 'Отменить',
    save: 'Сохранить',
    detail: 'Детали',
    edit: 'Редактировать',
    saveAndNext: 'Сохранить и продолжить',
    clear: 'Очистить',
    find: 'Искать'
  },
  notify: {
    noData: '!?!',
    success: 'Выполенено успешно',
    warning: 'Обратите внимание',
    error: 'Ошибка',
    info: 'Сообщение',
    validation: {
      success: 'Выполенено успешно',
      warning: 'Обратите внимание',
      error: 'Ошибка исполнения',
      info: 'Сообщение',
      required: 'Обязательное поле',
      invalidFormat: 'Значение не соответсвует допустимому  {value}',
      invalidLength: 'Длина должна быть {length}',
      serverError: 'Ошибка сервера (500)',
      invalidNumeric: 'Только цифры',
      noData: 'Нет данных',
      phoneNumber: "Введите корректный номер",
      email: "Некорректный адрес электронной почты",
      numeric: "Поле должно быть числом",
      minLength: "Не меньше {min} символов",
      maxLength: "Не больше {max} символов",
      phoneExist: "Такой номер уже существует",
      minValueUAH: "Не меньше {min} грн",
      sameAsPassword: 'Пароли не совпадают'
    }
  },
  tvStatuses: {
    0: 'Активно',
    1: 'Админ. блокировка',
    2: 'Не активировано',
    3: 'Заморожено',
    5: 'Низкий баланс',
    9: 'Деактивировано'
  },
  yes: 'Да',
  no: 'Нет',
  other: 'других',
  SRU: 'SRU',
  UA: 'UA',
  available: 'В наличии',
  notAvailable: 'Нет в наличии',
  priceValue: 'грн / 30 дней',
  priceFree: 'Бесплатно',
  notSelected: 'Не выбрано',
  moreInformation: 'Больше информации',
  lessInformation: 'Меньше информации',
  invalidAction: 'На данный момент это действие невозможно. Обратитесь в службу поддержки.',
  contactSupport: 'Связаться с поддержкой'
}
