import { API } from '@ananasbear/superapi'

const pluginOptions = {
	publicPages: ['/login', '/404'], // pages, where 401 error don`t relocation to login
	tokenKey: 'TOKEN', // key, what you set token in coockie or local store
	loginPath: '/login/enter-phone' // link authorization page
}

const api = new API(pluginOptions)

export default api
