<template lang="pug">
header
  nav.nav-header
    .header__container.flex-row-center
      .brand-logo
        a(:href="login ? '/' : 'https://dianet.online/'")
          img(src="@/assets/images/logo_dark.svg" width="115.71" alt="")
      .actions-btn
        ul
          li(v-if="windowWidth > 1024 || !login")
            Language
          li(v-if="clientProfile.login")
            .dropdown
              button#dropdownMessageButton.header-notification.icon-notification-bing.d-flex(:class="{'new-messages': countNotification}" @click="dropdownMessagesHandler")
              transition(name="fade")
                .dropdown-menu.dropdown-messages(v-if="isShowMessages") 
                  .flex-row-center.dropdown-messages__main-title
                    span.dropdown-messages__title {{ $t('header.message') }} 
                    span.dropdown-messages__new-messages {{ $tc('header.messageNew', getNewMessages.length) }}
                  template(v-for="message, index of getNewMessages")  
                    button.flex-row-center.dropdown-messages__item(@click="setChosenMessage(message, index)" :class="{'dropdown-messages__item--unread': !message.is_hidden}") 
                      span.dropdown-messages__item-title {{$i18n.locale === 'ua' ? message.title_ukr : message.title}}
                      span.dropdown-messages__item-date {{setMessageDate(message.date_send)}}
                  div.flex-row-center.justify-content-center   
                    router-link.dropdown-messages__more-btn(tag='button' @click.native="isShowMessages = false" to="/messages" ) {{ $t('header.messageMore') }}   
          li(v-if="uid && windowWidth > 1024")
            .dropdown
              button#dropdownMenuButton.dropdown__btn(@click="dropdownAccountHandler" :class="!clientProfile.avatars?.length ? 'icon-login' : ''")
                img#dropdownMenuButton.dropdown__btn-img(v-if="clientProfile.avatars?.length" :src="clientProfile.file" width="40" height="40")
              transition(name="fade")  
                .dropdown-menu.dropdown-account(v-if="isActive")
                  button#currentAcc.current-account(v-if="getCurrentAccount" @click="isShowAccounts = !isShowAccounts") 
                    i.icon-login(v-if="!getNotCurrentAccounts")
                    i.icon-arrow-down(v-else)
                    span.ml-1 {{ fio }}
                  .available-accounts(:style="{'height': `${getAvailableAccountsHeight}px`}")
                    template(v-for="account of getNotCurrentAccounts")
                      .flex-row-center.available-account
                        button(@click="changeAccount(account.login)") {{ account.address || $t('modal.addressHide')}}
                        button.icon-trash(@click="logoutAnotherAcc(account.login)")
                  .divider
                  button.flex-row-center.dropdown-account__item.add-account(v-if="getCurrentAccount" @click="isActiveModal = !isActiveModal")
                    i.icon-add   
                    | {{ $t('modal.addAccount') }} 
                  button.flex-row-center.dropdown-account__item(@click="logout")
                    i.icon-logout    
                    | {{ $t('header.logout') }}
                  .flex-row-center.dropdown-account__item.switch-theme 
                    .d-flex.align-items-center
                      i.icon-edit   
                      | {{ $t('header.theme') }}
                    v-switch.mt-0.pt-0.theme-switcher(v-model="isDarkTheme" @change="setTheme" hide-details inset dark dense)
      #mob-menu.nav-control(v-if="fio" @click="openMobileSide")
        .hamburger(:class="isShowSideBar ? 'is-active' : ''")
          ul.burgerActive.ml-0
            each val in ['', '', '']
              li.line= val
  AddAccount(:is-open.sync="isActiveModal")           
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import AddAccount from './Modals/AddAccount.vue'
import Language from './Language.vue'
import { setMessageDate } from '@/configs/utils'

import '@/assets/sass/header.sass'

export default {
	name: 'Header',
	components: {
		AddAccount,
		Language
	},
	data () {
		return {
			isActive: false,
			isActiveModal: false,
      isShowAccounts: false,
      isShowMessages: false,
      isDarkTheme: false
		}
	},
	computed: {
		...mapState({
			userListAccount: state => state.user.accounts,
			isShowSideBar: state => state.app.isShowSideBar,
      countNotification: state => state.messages.messagesData.count_notification,
      messagesData: state => state.messages.messagesData,
      fio: state => state.user.userInfo.fio,
      login: state => state.user.userInfo.login,
      uid: state => state.user.userInfo.uid,
      windowWidth: state => state.app.windowWidth,
      clientProfile: state => state.user.clientProfile,
      darkTheme: state => state.profile.isDarkTheme
		}),
		getCurrentAccount () {
			return this.userListAccount?.find( account => account.curr)
		},
    getNotCurrentAccounts () {
      return this.userListAccount?.filter( account => !account.curr)
    },
    getNewMessages () {
      return this.messagesData?.results?.slice(0, this.countNotification <= 4 ? this.countNotification : 4)
    },
    getAvailableAccountsHeight () {
      return this.isShowAccounts && this.getNotCurrentAccounts ? this.getNotCurrentAccounts.length * 48 : 0
    }
	},
	mounted () {
    if (this.getCurrentAccount) this.getMessages()
		document.addEventListener('click', this.clickOutsideHandler)
    this.isDarkTheme = this.darkTheme
	},
  beforeDestroy () {
    window.removeEventListener('click', this.clickOutsideHandler)
  },
	methods: {
		...mapActions([
			'postChangeAccount',
			'getUserListAccount',
			'postLogoutAnotherAcc',
      'getMessages'
		]),
		...mapMutations({
			showSideBar: 'SET_APP',
      setDataMessages: 'SET_DATA_MESSAGES',
      setDataProfile: 'SET_DATA_PROFILE'
		}),
    setMessageDate,
		async changeAccount (login) {
			await this.postChangeAccount({ body: { login } })
            this.$router.go()
		},
		async logoutAnotherAcc (login) {
			await this.postLogoutAnotherAcc({ body: { login } })
			this.getUserListAccount('list_account')
		},
    logout () {
      this.$auth.logout()
      this.$router.go()
    },
    dropdownAccountHandler () {
      this.isShowMessages = false
      this.isActive = !this.isActive
      this.isShowAccounts =  false
    },
    dropdownMessagesHandler () {
      if (this.windowWidth < 769 ) {
        if (this.$route.name !== 'messages') this.$router.push('/messages')
      } else {
        this.isActive = false
        this.isShowAccounts =  false
        this.isShowMessages = !this.isShowMessages
      }
    },
    setChosenMessage (message, index) {
      this.setDataMessages({chosenMessage: {message, index}})
      this.isShowMessages = false
      if(this.$route.name !== 'messages') this.$router.push('/messages')
      this.getMessages()
    },
    clickOutsideHandler (e) {
      if (e.target.id !== 'dropdownMenuButton' && this.isActive && (e.target.id !== 'currentAcc' && 
      e.target.parentNode.id !== 'currentAcc') && !e.target.closest('.theme-switcher')) {
				this.isActive = false
        this.isShowAccounts =  false
			}
      if (e.target.id !== 'dropdownMessageButton' && this.isShowMessages && 
      !e.target.closest('.dropdown-messages')) {
        this.isShowMessages = false
      }
    },
    openMobileSide () {
      this.showSideBar({isShowSideBar: !this.isShowSideBar})
      window.scrollTo({
          top: 0,
          behavior: "smooth"
      })
    },
    setTheme (e) {
      this.setDataProfile({ isDarkTheme: e })
      localStorage.setItem('isDarkTheme', JSON.stringify(e))
    }
	}
}
</script>
