<template lang="pug">
v-dialog.modal.modalQR(v-model="isOpen" @click:outside="cancel" content-class="elevation-0")
  .modal-dialog.modalQR-dialog
    .modal-content.modalQR-content
      .modal-header.modalQR-header
        span.modalQR-header__title {{ $t('sidebar.mobileApp') }}
        button.close(@click="cancel")
      .modal-body.modalQR-body
        img.qrcode(:src="`data:image/png;base64, ${qr}`" width="158" height="158")
        .modalQR-body__text {{ $t('sidebar.scanQR') }}
      .modal-footer.modalQR-footer  
        button.mainButton.secondaryButton(@click="cancel") {{ $t('close') }}
</template>
<script>
export default {
	name: 'ModalQR',
	props: {
		isOpen: {
			type: Boolean,
			default: false
		},
    qr: {
      type: String,
      default: ''
    }
	},
	methods: {
		cancel () {
			this.$emit('update:is-open', false)
		},
		submit () {
			this.$emit('emitSubmit', true)
		}
	}
}
</script>
