import { authUrls } from '@/configs/endpoints'
import api from '@/plugins/api'

const options = {
    tokenKey: 'token',
    urls: authUrls
}

class Authorization {
    isAuth () {
		return localStorage.getItem('TOKEN')
    }

    logout () {
        localStorage.removeItem('TOKEN')
        document.cookie = 'TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        document.cookie = 'uid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        localStorage.removeItem('isAdmin')
    }

    setToken (key) {
        localStorage.setItem('TOKEN', key)
        const d = new Date()
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000))
        let expires = "expires="+d.toUTCString()
		document.cookie = `TOKEN=${ key };${expires};path=/;`
    }

    byCredits (data) {
        this.logout()
        return api.post(`${options.urls.general.login}`, { body: data }).then( response => {
            if (response.data.token) {
                this.setToken(response.data[options.tokenKey])
				delete response.data[options.tokenKey]
                return response
            } else return response
        })
    }

    async byAdmin (data) {
        this.logout()
        const res = await api.get(options.urls.general.admin + data)
        this.setToken(res.data[options.tokenKey])
        localStorage.setItem('isAdmin', true)
    }
}

const authorization = new Authorization(options)

export default authorization
