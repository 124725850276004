<template lang="pug">
.page-template
  slot(name="title")
    .d-flex.align-center.mb-8
      img(
        v-if="showPageBack"
        src="@/assets/images/arrow-left.svg"
        width="24"
        height="24"
        alt="arrow-left"
        @click="$router.go(-1)"
      ).page-goBack
      .page-title(v-if="title") {{ title }}
  Loader(v-if="isLoading")
  slot(v-else name="content")
</template>
<script>
export default {
  name: 'PageTemplate',
  props: {
    title: { type: String, default: '' },
    showPageBack: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false }
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
</script>
