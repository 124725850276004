import ru from '@/configs/translations/ru'
import ua from '@/configs/translations/ua'

let locale = localStorage.getItem('lang') === 'ru' ? 'ru' : 'ua'

export default {
  locale,
  fallbackLocale: 'ua',
  availableLocales: [{
    code: 'ru',
    flag: 'ru',
    label: 'Russian',
    messages: ru
  }, {
    code: 'ua',
    flag: 'ua',
    label: 'Ukrainian',
    messages: ua
  }]
}
