import Vue from 'vue'
import Router from 'vue-router'

// import store from '../store'
// import { access } from '@/configs/permissions'

Vue.use(Router)

export const routes = [
  {
    path: '*',
    name: 'all',
    redirect: '/404'
  },
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/Main')
  },
  {
    path: '/index',
    redirect: '/'
    // name: 'main',
    // component: () => import('@/views/Main')
  },
  {
    path: '/admin_login/:id',
    name: 'admin',
    component: () => import('@/views/LoadAdmin')
  },
  {
    path: '/choose-account',
    name: 'choose-account',
    component: () => import('@/views/ChooseAccount')
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/Payment')
  },
  {
    path: '/bonuses',
    name: 'bonuses',
    component: () => import('@/views/Bonuses')
  },
  {
    path: '/tariffs',
    name: 'tariffs',
    component: () => import('@/views/Tariffs/index.vue')
  },
  {
    path: '/tariffs/constructor',
    name: 'tariffs-constructor',
    component: () => import('@/views/Tariffs/Constructor')
  },
  {
    path: '/tv',
    name: 'tv',
    component: () => import('@/views/TV/index.vue')
  },
  {
    path: '/tv/:service(sweetTv|megogo)',
    name: 'tv-service',
    component: () => import('@/views/TV/TelevisionService.vue')
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('@/views/Messages')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/Support')
  },
  {
    path: '/404',
    name: 'no-found',
    component: () => import('@/views/NotFound')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile')
  },
  {
    path: '/r_m',
    name: 'RedirectPage',
    meta: { guest: true },
    component: () => import('@/views/RedirectPage')
  },
  {
    path: '/login',
    name: 'login',
    meta: { guest: true },
    component: () => import('@/views/Login'),
    children:[
      {
        name: 'enter-phone',
        path: 'enter-phone',
        component: () => import('@/components/AuthForms/EnterPhone'),
        meta: { guest: true }
      },
      {
        name: 'log-pass',
        path: 'log-pass',
        component: () => import('@/components/AuthForms/LogPass'),
        meta: { guest: true }
      },
      {
        name: 'sms-code',
        path: 'sms-code',
        component: () => import('@/components/AuthForms/SMSCode'),
        meta: { guest: true }
      },
      {
        name: 'qr-code',
        path: 'qr-code',
        component: () => import('@/components/AuthForms/Qrcode_'),
        meta: { guest: true }
      }
    ]
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      // If there is a saved position (e.g., when going back), return it
      return savedPosition
    } else if (to.hash) {
      // If the route contains hash, scroll to the element
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      // Scroll to the top of the page
      return { x: 0, y: 0 }
    }
  }
})

const notRedirectToMain = ['/choose-account', '/r_m']

router.beforeEach(async (to, from, next) => {
  if (to.query.lang === 'ru' || to.query.lang === 'ua') localStorage.setItem('lang', to.query.lang)
  if (to.path === '/login' || to.path === '/login/') next('/login/enter-phone')
  else if (!Vue.prototype.$auth.isAuth()) {
    if (!to.meta.guest) {
      localStorage.setItem('route', encodeURIComponent(window.location.pathname))
      next('/login/enter-phone')
    }
    next()
  } else {
    if (to.meta.guest && !notRedirectToMain.includes(to.path)) next('/')
    next()
  }
})


export default router
