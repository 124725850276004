<template lang="pug">
.lang
  template(v-if="isUserUid")
    i.icon-lang
    span.language {{ $t('header.language') }}
  .lang-inner
    span.lang__item(v-for="(lang, i) in languageArray" :key="`lang${i}`" :class="{'lang__item--active': lang === selectedLang.toLowerCase()}" @click="handleChooseLang(lang)") {{ lang }}
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'Language',
	data () {
		return {
			languageArray: ['ua', 'ru']
		}
	},
  computed: {
    ...mapState({
			selectedLang: state => state.user.clientProfile.language,
      isUserUid: state => state.user.userInfo.uid,
      windowWidth: state => state.app.windowWidth
		})
  },
  watch: {
    selectedLang (lang) {
      const currentLang = localStorage.getItem('lang')
      if (lang && currentLang && lang.toLowerCase() !== currentLang && currentLang !== 'null') 
        this.handleChooseLang(localStorage.getItem('lang'), true)
    }
  },
	methods: {
    ...mapActions(['patchClientProfile']),
    ...mapMutations({
      setDataUser: 'SET_DATA_USER'
    }),
		async handleChooseLang (lang, isUpdateProfile) {
			if (this.$i18n.locale !== lang) {
        if (this.isUserUid) {
          await this.patchClientProfile({ body: {language: lang.toUpperCase()} })
        }
        else this.setDataUser({
          clientProfile: {
          language: lang
        }
       })
        localStorage.setItem('lang', lang)
        this.$i18n.locale = lang
			} else if (isUpdateProfile) await this.patchClientProfile({ body: {language: lang.toUpperCase()} })
		}
	}
}
</script>
