<template lang="pug">
include ../../configs/templatesPUG/template.pug
v-dialog.modal(v-model="isOpen" @click:outside="cancel" content-class="elevation-0")
  .login-start.addAccount
    .authFormContainer.m-0
      .authForm
        +h4('modal.enterLogPas').autoriz
        form(v-on:submit.prevent="addAccount").position-relative
          Loader(v-if="isLoading" is-content)
          .form-group.mb-2
            p.add-account-error(v-if="errorMessage").color-orange {{ errorMessage }}
            label.mb-2 {{$t('modal.login')}}
            v-text-field(
              v-model="modalForm.login"
              @input="$v.modalForm.login.$touch"
              :error-messages="$valid.validation(this.$v.modalForm.login, ['required', 'numeric'], '')"
              placeholder="01714"
              solo).form-control.set-login-pass.w-100
          .form-group.mb-8
            label.mb-2 {{$t('modal.password')}}
            v-text-field(
              v-model="modalForm.password"
              @input="$v.modalForm.password.$touch"
              :error-messages="$valid.validation(this.$v.modalForm.password, ['required'], '')"
              placeholder="093456"
              flat
              solo).form-control.set-login-pass.w-100
          .text-center.num-block
            button(type="button" @click="cancel").mainButton.secondaryButton.ml-6.mb-0 {{$t('toCancel')}}
            button(type="button" @click="addAccount").mainButton.mb-0 {{$t('add')}}
</template>
<script>
import { mapActions } from 'vuex'
import { formLogin } from '@/configs/validationRules'
export default {
    name: 'AddAccount',
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        errorMessage: '',
        isLoading: false,
        modalForm: {
            login: '',
            password: ''
        }
    }),
    validations: {
        modalForm: formLogin
    },
    watch:{
      modalForm: {
        handler () {
          console.log(this)
          if (this.errorMessage) this.errorMessage = ''
        },
        deep: true
      }
    },
    methods: {
      	...mapActions([
            'getUserInfo',
            'getClientProfile',
            'postChangeAccount',
            'getUserListAccount',
            'postLogoutAnotherAcc',
            'postAddAccount',
            'getMessages',
            'getSweetTVLink',
            'getTariffs',
            'getBonuses',
            'getTVInfo'
          ]),
        async addAccount () {
            this.$v.$touch()
            if (
              !this.$v.modalForm.$invalid
            ) {
                let relogin = true
                this.isLoading = true
                const response = await this.postAddAccount({
                    body: {
                        ...this.modalForm,
                        relogin
                    }
                })

                switch (response.status) {
                    case 0:
                        await this.getUserListAccount('list_account')
                        await this.getUserInfo()
                        this.getTariffs()
                        this.getClientProfile()
                        this.getUserListAccount('list_account')
                        this.getBonuses()
                        this.getTVInfo()
                        // this.getSweetTVLink({
                        //     site_id: 2
                        // })
                        this.getMessages()
                        this.cancel()
                        break
                    case 1:
                        this.errorMessage = this.$t('modal.invalidLoginPassword')
                        break
                    default:
                        this.errorMessage = this.$t('modal.error')
                }
                this.isLoading = false
            }
        },
        cancel () {
            this.$emit('update:is-open', false)
            this.modalForm.login = ''
            this.modalForm.password = ''
            this.isLoading = false
        }
    }
}
</script>
