<template lang="pug">
footer.footer
  a.footer__link(href="https://dianet.online/contracts?id=6#lkRules" target="_blank") {{$t('footer.terms')}}
</template>

<script>
import '@/assets/sass/footer.sass'

export default {
	name: 'Footer',
	data () {
		return {}
	}
}
</script>
